import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// import Compressor from "compressorjs";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SelectBox, SlideButton, TextBox, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, assestType, singleFileUpload, allowed_file_size, generateImageUrl, img_size, Bold, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  iconButtonStyle: {
    height: "44px",
    padding: "12px 16px 12px 16px",
    borderRadius: "8px",
    boxShadow: "0px 2px 6px #00000021",
    // backgroundColor: "#EEF9EE",
    "&:hover": {
      // backgroundColor: "#EEF9EE"
    },
  },
  saveNUpdateButtonTextStyle: {
    fontSize: "0.875rem",
    color: "white",
    fontWeight: "bold",
  },
  saveNUpdateButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },

  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },
  uploadimg: {
    backgroundColor: "white",
    borderRadius: "10px",
    textAlign: "center",
    padding: "8px",
  },
  profile_title: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
  }
}));

const InitialData = {
  property: "",
  serviceType: "",
  servicePersonName: "",
  unit: [],
  gate: "",
  error: {
    property: "",
    serviceType: "",
    servicePersonName: "",
    gate: "",
    unit: "",
  },
};
const InitialDataVendor = {
  unitVendor: [],
  property: "",
  vendorType: "",
  vendorPersonName: "",
  gate: "",
  error: {
    property: "",
    unitVendor: "",
    vendorType: "",
    vendorPersonName: "",
    gate: "",
  },
};
export const SwiftInOutCreate = (props) => {
  const { t = () => false } = props;
  const classes = useStyles();
  const size = useWindowDimensions();
  const history = useHistory();
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const companyID = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))?.[0]?.company_id

  const [createData, setCreateData] = React.useState({ ...InitialData });
  const [createDataVendor, setCreateDataVendor] = React.useState({
    ...InitialDataVendor,
  });
  const [url_1, setUrl_1] = React.useState("");
  const [url_2, setUrl_2] = React.useState("");
  const search = useLocation().search;
  const Title = new URLSearchParams(search).get("create");
  const alert = React.useContext(AlertContext);
  const [disable, setDisable] = React.useState(null);
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;


  const updateState = (key, value) => {
    if (key === "property") {
      let error = createData.error;
      error[key] = "";
      setCreateData({
        ...createData,
        [key]: value,
        error,
        unit: "",
        gate: "",
        serviceType: "",
      });
    } else {
      let error = createData.error;
      error[key] = "";
      setCreateData({ ...createData, [key]: value, error });
    }
    if (key === "unit") {
      let error = createData.error;
      error[key] = "";
      setCreateData({
        ...createData,
        [key]: value,
        error,
        gate: "",
        serviceType: "",
      });
    } else {
      let error = createData.error;
      error[key] = "";
      setCreateData({ ...createData, [key]: value, error });
    }
  };
  const updateStateVendor = (key, value) => {

    if (key === "property") {
      let error = createDataVendor.error;
      error[key] = "";
      setCreateDataVendor({
        ...createDataVendor,
        [key]: value,
        error,
        unitVendor: "",
        gate: "",
        serviceType: "",
      });
    } else {
      let error = createDataVendor.error;
      error[key] = "";
      setCreateDataVendor({ ...createDataVendor, [key]: value, error });
    }
    if (key === "unitVendor") {
      let error = createDataVendor.error;
      error[key] = "";
      setCreateDataVendor({
        ...createDataVendor,
        [key]: value,
        error,
        gate: "",
        vendorType: "",
      });
    } else {
      let error = createDataVendor.error;
      error[key] = "";
      setCreateDataVendor({ ...createDataVendor, [key]: value, error });
    }

  };

  const isIamValide = () => {
    let isValid = true;
    let error = createData.error;
    //Property name
    if (createData.property.length === 0) {
      isValid = false;
      error.property = t("Property is Required");
    }
    //unit name
    if (createData.unit.length === 0) {
      isValid = false;
      error.unit = t("unit is Required");
    }
    //serviceType name
    if (createData.serviceType.length === 0) {
      isValid = false;
      error.serviceType = t("ServiceType is Required");
    }
    //servicePersonName name
    if (createData.servicePersonName.length === 0) {
      isValid = false;
      error.servicePersonName = t("Service Person Name is Required");
    }
    //gate name
    if (createData.gate.length === 0) {
      isValid = false;
      error.gate = t("Service Person Name is Required");
    }
    if (createData.property.length === 0 || createData.unit.length === 0 || createData.serviceType.length === 0 || createData.servicePersonName.length === 0 || createData.gate.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory fields"),
      });
    }

    setCreateData({ ...createData, error });

    return isValid;
  };
  const isIamValideVendor = () => {
    let isValid = true;
    let error = createDataVendor.error;
    //Property name
    if (createDataVendor.property.length === 0) {
      isValid = false;
      error.property = t("Property is Required");
    }
    //unit name
    if (createDataVendor.unitVendor.length === 0) {
      isValid = false;
      error.unitVendor = t("unit is Required");
    }
    //Vendor type name
    if (createDataVendor.vendorType.length === 0) {
      isValid = false;
      error.vendorType = t("vendorType is Required");
    }
    //vendorPersonName name
    if (createDataVendor.vendorPersonName.length === 0) {
      isValid = false;
      error.vendorPersonName = t("Vendor Person Name is Required");
    }
    //gate name
    if (createDataVendor.gate.length === 0) {
      isValid = false;
      error.gate = t("Gate is Required");
    }
    if (createDataVendor.property.length === 0 || createDataVendor.unitVendor.length === 0 || createDataVendor.vendorType.length === 0 || createDataVendor.vendorPersonName.length === 0 || createDataVendor.gate.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory fields"),
      });
    }

    setCreateDataVendor({ ...createDataVendor, error });

    return isValid;
  };

  const uploadS3 = async (data) => {
    try {
      let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantid}`, type: assestType?.General_Images }, alert, allowed_file_size)
      if (uploaded_file?.[0]?.url) {
        setUrl_1(uploaded_file?.[0]?.url);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      }
    }
    catch (err) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong."),
      });
    }
  };
  const uploadS3_2 = async (data) => {

    try {
      let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantid}`, type: assestType?.General_Images }, alert, allowed_file_size)
      if (uploaded_file?.[0]?.url) {
        setUrl_2(uploaded_file?.[0]?.url);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      }
    }
    catch (err) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong."),
      });
    }
  };

  const ServiceCreate = () => {
    if (isIamValide()) {
      setDisable(true)
      const units = createData?.unit?.map((e) => {
        return e?.value
      })
      const payload = {
        access_gate_id: createData?.gate?.value,
        check_in_by: userProfileId,
        check_in_time: new Date().toISOString(),
        created_at: new Date().toISOString(),
        created_by: userProfileId,
        is_active: true,
        person_name: createData?.servicePersonName,
        property_id: createData?.property?.value,
        provider_id: createData?.serviceType?.value,
        updated_at: new Date().toISOString(),
        updated_by: userProfileId,
        visitor_type: "SP",
        client: clientId,
        event: "Check-In",
        unit_ids: units
      }
      NetworkCall(
        `${config.api_url}/security/upsert_swift_in_out_logs`,
        NetWorkCallMethods.post, payload, null, true, false
      ).then(() => {
        alert.setSnack({
          ...alert, open: true, msg: t("Service provider created"),
          severity: AlertProps.severity.success,
        });
        setDisable(false);
        history.goBack(-1)
      }).catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert, open: true, msg: t("Something Went Wrong"),
          severity: AlertProps.severity.error,
        });
        setDisable(false);
      });
    } else {
      return false;
    }
  };

  const VendorCreate = () => {
    if (isIamValideVendor()) {
      setDisable(true)
      const units = createDataVendor?.unitVendor?.map((e) => {
        return e?.value
      })
      const payload = {
        access_gate_id: createDataVendor?.gate?.value,
        check_in_by: userProfileId,
        check_in_time: new Date().toISOString(),
        created_by: userProfileId,
        is_active: true,
        property_id: createDataVendor?.property?.value,
        person_name: createDataVendor?.vendorPersonName,
        vendor_id: createDataVendor?.vendorType?.value,
        visitor_type: "VE",
        vendor_image: url_1.length > 0 ? url_1 : "",
        vehicle_image: url_2.length > 0 ? url_2 : "",
        client: clientId,
        event: "Check-In",
        unit_ids: units
      }
      NetworkCall(
        `${config.api_url}/security/upsert_swift_in_out_logs`,
        NetWorkCallMethods.post, payload, null, true, false
      ).then(() => {
        alert.setSnack({
          ...alert, open: true, msg: t("Vendor Entry created"),
          severity: AlertProps.severity.success,
        });
        setDisable(false);
        history.push(Routes.vendor);
      }).catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert, open: true, msg: t("Something Went Wrong"),
          severity: AlertProps.severity.error,
        });
        setDisable(false);
      });
    } else {
      return false;
    }
  };

  const [loading, setLoading] = React.useState(null);

  const loadOptions = async (search = "", array, type) => {
    setLoading(type);
    let result, offset = 0, limit = 10, payload;
    if (search && !Boolean(array?.length)) {
      offset = 0;
    }
    else {
      offset = array?.length;
    }
    switch (type) {
      case 'property':
        const roleID = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;

        if (!roleID?.length) {
          setLoading(null);
          return { options: [] }
        }

         payload = { roleID, search, offset, limit};

        result = await NetworkCall(
          `${config?.api_url}/queries/access_control/property_list`,
          NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => response?.data?.data
        ).catch((err) => console.log(err))

        return {
          options: result?.access_control?.map((val) => val.property),
          hasMore: (array?.length + result?.access_control?.map((val) => val.property)?.length) < result?.count
        }

      case 'unit':
        if (!createData?.property?.value?.length) {
          setLoading(null);
          return { options: [] }
        }

        payload = {
          property_ids: [createData?.property?.value], status: [true],
          search, offset, limit,
        };

        result = await NetworkCall(
          `${config?.api_url}/queries/unit/list`,
          NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => res?.data).catch((err) => console.log(err));

        setLoading(null);
        if (!result?.data?.unit.length) return { options: [] }
        return {
          options: [...result?.data?.unit],
          hasMore: (array?.length + result?.data?.unit?.length) < result?.data?.count
        }
      case 'unitVendor':
        if (!createDataVendor?.property?.value?.length) {
          setLoading(null);
          return { options: [] }
        }

        payload = {
          property_ids: [createDataVendor?.property?.value], status: [true],
          search, offset, limit,
        };

        result = await NetworkCall(
          `${config?.api_url}/queries/unit/list`,
          NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => res?.data).catch((err) => console.log(err));

        setLoading(null);
        if (!result?.data?.unit?.length) return { options: [] }

        return {
          options: [...result?.data?.unit],
          hasMore: (array?.length + result?.data?.unit?.length) < result?.data?.count
        }
      case 'serviceType':
        if (!createData?.property?.value?.length) {
          setLoading(null);
          return { options: [] }
        }

        payload = { company_id: companyID, search, offset, limit };

        result = await NetworkCall(
          `${config?.api_url}/queries/service_providers_master/list`,
          NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => res?.data).catch((err) => console.log(err));

        setLoading(null);
        if (!result?.data?.service_providers_master.length) return { options: [] }
        return {
          options: [...result?.data?.service_providers_master],
          hasMore: (array?.length + result?.data?.service_providers_master?.length) < result?.data?.count
        }
      case 'vendorType':
        if (!createDataVendor?.property?.value?.length) {
          setLoading(null);
          return { options: [] }
        }

        payload = { company_id: companyID, search, offset, limit };

        result = await NetworkCall(
          `${config?.api_url}/queries/vendor_master/list`,
          NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => res?.data).catch((err) => console.log(err));

        setLoading(null);
        if (!result?.data?.vendor_master.length) return { options: [] }
        return {
          options: [...result?.data?.vendor_master],
          hasMore: (array?.length + result?.data?.vendor_master?.length) < result?.data?.count
        }

      case "gate":
        if (!createData?.property?.value?.length) {
          setLoading(null);
          return { options: [] };
        }

        payload = { property_id: createData?.property?.value, search, offset, limit };

        result = await NetworkCall(
          `${config?.api_url}/queries/access_gates_master/list`,
          NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => res?.data).catch((err) => console.log(err));

        setLoading(null);
        return {
          options: [...result?.data?.access_gates_master],
          hasMore:
            array?.length + result?.data?.access_gates_master?.length < result?.data?.count,
        };
      case "gatevendor":
        if (!createDataVendor?.property?.value?.length) {
          setLoading(null);
          return { options: [] };
        }

        payload = { property_id: createDataVendor?.property?.value, search, offset, limit };

        result = await NetworkCall(
          `${config?.api_url}/queries/access_gates_master/list`,
          NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => res?.data).catch((err) => console.log(err));

        setLoading(null);
        return {
          options: [...result?.data?.access_gates_master],
          hasMore:
            array?.length + result?.data?.access_gates_master?.length < result?.data?.count,
        };
      default:
        return { options: [] }
    }
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        {Title === "Vendor" && (
          <TitleBar
            text={t("Create Vendor Entry")}
            // color="#5078E1"
            goBack={() => history.goBack()}
          />
        )}
        {Title === "Service" && (
          <TitleBar
            text={t("Create Service Provider")}
            // color="#5078E1"
            goBack={() => history.goBack()}
          />
        )}
        <div
          className={classes.root}
          style={{ height: size.height - 135, overflow: "scroll" }}
        >
          {Title === "Vendor" && (
            <div style={{ padding: "16px" }}>
              <Grid container className={classes.uploadimg}>
                <Grid item xs={6} borderRight="1px solid #E4E8EE">
                  <Typography className={classes.profile_title}>{t('vendor_image')}</Typography>
                  <Box mt={0.5}>
                    <IconButton className={classes.profileimg}>
                      {url_1.length > 0 ? (
                        <Avatar
                          variant="circular"
                          style={{ height: 70, width: 70 }}
                          src={generateImageUrl(url_1, img_size.small_square)}
                        >
                          {/* <img src={url_1} alt="//" /> */}
                        </Avatar>
                      ) : (
                        <PersonOutlineIcon className={classes.profileicon} />
                      )}
                      <label htmlFor="uplodebtn">
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          className={classes.profileimgs}
                          type="file"
                        >
                          <img
                            src={"/images/icons8-camera.svg"}
                            alt=" "
                            className={classes.profileicons}
                          />
                        </Avatar>
                      </label>
                      <input
                        type="file"
                        name="img"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(value) => {
                          uploadS3(value.target.files[0]);
                        }}
                        id="uplodebtn"
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.profile_title}>{t('vehicle_image')}</Typography>
                  <Box mt={0.5}>
                    <IconButton className={classes.profileimg}>
                      {url_2.length > 0 ? (
                        <Avatar
                          variant="circular"
                          style={{ height: 70, width: 70 }}
                          src={generateImageUrl(url_2, img_size.small_square)}
                        >
                          {/* <img src={url_2} alt="//" /> */}
                        </Avatar>
                      ) : (
                        <PersonOutlineIcon className={classes.profileicon} />
                      )}
                      <label htmlFor="uplodebtn2">
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          className={classes.profileimgs}
                          type="file"
                        >
                          <img
                            src={"/images/icons8-camera.svg"}
                            alt=" "
                            className={classes.profileicons}
                          />
                        </Avatar>
                      </label>
                      <input
                        type="file"
                        name="img2"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(value) => {
                          uploadS3_2(value.target.files[0]);
                        }}
                        id="uplodebtn2"
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
          {Title === "Service" && (
            <Grid container p={2} spacing={2}>
              <Grid item xs={12}>
                <SelectBox
                  label={t("Choose_Property")}
                  //options={property}
                  value={createData.property}
                  placeholder={t('Select_Property')}
                  onChange={(value) => {
                    updateState("property", value);
                  }}
                  isError={createData?.error?.property?.length > 0}
                  errorMessage={createData?.error?.property}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'property')}
                  loading={loading === "property"}
                  debounceTimeout={800}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  label={t('Choose_Unit')}
                  isMulti
                  key={JSON.stringify(createData?.property)}
                  value={createData?.unit}
                  placeholder={t('Select_Unit')}
                  onChange={(value) => {
                    updateState("unit", value);
                  }}
                  isError={createData?.error?.unit?.length > 0}
                  errorMessage={createData?.error?.unit}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'unit')}
                  loading={loading === "unit"}
                  debounceTimeout={800}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  // options={service}
                  value={createData.serviceType}
                  placeholder={t('Select_Service')}
                  label={t('Choose_Service')}
                  key={JSON.stringify(createData?.property)}
                  onChange={(value) => updateState("serviceType", value)}
                  isError={createData?.error?.serviceType?.length > 0}
                  errorMessage={createData?.error?.serviceType}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'serviceType')}
                  loading={loading === "serviceType"}
                  debounceTimeout={800}
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  color
                  value={createData.servicePersonName}
                  placeholder={t('Enter_Service')}
                  label={t('Service')}
                  onChange={(value) =>
                    updateState("servicePersonName", value.target.value)
                  }
                  isError={createData?.error?.servicePersonName?.length > 0}
                  errorMessage={createData?.error?.servicePersonName}
                  isRequired
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  label={t('Choose_Gate')}
                  value={createData.gate}
                  placeholder={t('Select_Gate')}
                  onChange={(value) => updateState("gate", value)}
                  key={JSON.stringify(createData?.unit)}
                  isError={createData?.error?.gate?.length > 0}
                  errorMessage={createData?.error?.gate}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'gate')}
                  loading={loading === "gate"}
                  debounceTimeout={800}
                />
              </Grid>
            </Grid>
          )}
          {Title === "Vendor" && (
            <Grid container p={2} spacing={2}>

              <Grid item xs={12}>
                <SelectBox
                  label={t('Choose_Property')}
                  value={createDataVendor.property}
                  placeholder={t("Select_Property")}
                  onChange={(value) => {
                    updateStateVendor("property", value);
                  }}
                  isError={createDataVendor?.error?.property?.length > 0}
                  errorMessage={createDataVendor?.error?.property}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'property')}
                  loading={loading === "property"}
                  debounceTimeout={800}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  label={t('Choose_Unit')}
                  isMulti
                  value={createDataVendor?.unitVendor}
                  placeholder={t('Select_Unit')}
                  key={JSON.stringify(createDataVendor?.property)}
                  onChange={(value) => {
                    updateStateVendor("unitVendor", value);
                  }}
                  isError={createDataVendor?.error?.unitVendor?.length > 0}
                  errorMessage={createDataVendor?.error?.unitVendor}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'unitVendor')}
                  loading={loading === "unitVendor"}
                  debounceTimeout={800}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  value={createDataVendor.vendorType}
                  placeholder={t("Select_Vendor")}
                  label={t("Choose_Vendor")}
                  onChange={(value) => updateStateVendor("vendorType", value)}
                  isError={createDataVendor?.error?.vendorType?.length > 0}
                  errorMessage={createDataVendor?.error?.vendorType}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'vendorType')}
                  loading={loading === "vendorType"}
                  debounceTimeout={800}
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  color
                  value={createDataVendor.vendorPersonName}
                  placeholder={t("Enter_Vendor")}
                  label={t("Vendor_Person")}
                  onChange={(value) =>
                    updateStateVendor("vendorPersonName", value.target.value)
                  }
                  isError={
                    createDataVendor?.error?.vendorPersonName?.length > 0
                  }
                  errorMessage={createDataVendor?.error?.vendorPersonName}
                  isRequired
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  label={t('Choose_Gate')}
                  value={createDataVendor.gate}
                  placeholder={t('Select_Gate')}
                  onChange={(value) => updateStateVendor("gate", value)}
                  key={JSON.stringify(createDataVendor?.unit)}
                  isError={createDataVendor?.error?.gate?.length > 0}
                  errorMessage={createDataVendor?.error?.gate}
                  isRequired
                  isPaginate
                  loadOptions={(search, array) => loadOptions(search, array, 'gatevendor')}
                  loading={loading === "gatevendor"}
                  debounceTimeout={800}
                />
              </Grid>
            </Grid>
          )}
        </div>
        {Title === "Service" && (
          <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
            <SlideButton
              mainText={`${t("Swipe to Check In")}`}
              overlayText={t("Checked In")}
              classList={"my-class1"}
              caretClassList={"my-caret-class1"}
              overlayClassList={"my-overlay-class1"}
              onSlideDone={ServiceCreate}
              disabled={disable}
            />
            {/* <Button
              variant="contained"
              fullWidth
              className={classes.iconButtonStyle}
              color="primary"
              onClick={ServiceCreate}
              disabled={disable}

            >
              <Typography className={classes.saveNUpdateButtonTextStyle}>
                {t('Check_In')}
              </Typography>
            </Button> */}
          </Grid>
        )}
        {Title === "Vendor" && (
          <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
            <SlideButton
              mainText={`${t("Swipe to Check In")}`}
              overlayText={t("Checked In")}
              classList={"my-class1"}
              caretClassList={"my-caret-class1"}
              overlayClassList={"my-overlay-class1"}
              onSlideDone={VendorCreate}
              disabled={disable}
            />
            {/* <Button
              variant="contained"
              fullWidth
              color="primary"
              className={classes.iconButtonStyle}
              onClick={VendorCreate}
              disabled={disable}
            >
              <Typography className={classes.saveNUpdateButtonTextStyle}>
                {t('Check_In')}
              </Typography>
            </Button> */}
          </Grid>
        )}
      </Container>
    </>
  );
};
export default withNamespaces("addvendor")(SwiftInOutCreate);