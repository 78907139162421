import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SelectBox, SlideButton, TextBox, TitleBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, allowed_file_size, Bold, customEnumModifier, enumSelect, enumTypes, ExtraBold, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods, Regular, remCalc, SemiBold, singleFileUpload } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import VehicleCard from "./vehicleCard";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    margin: "12px 10px 8px 14px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: Regular,
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  userimg: {
    borderRadius: "50%",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  stack: {
    backgroundColor: "#F5F7FA",
    margin: "12px",
  },
  stack1: {
    borderRight: "1px solid #E4E8EE",
  },
  preview: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.Tertiary,
    textAlign: "center",
    marginBottom: "8px",
  },
  input: {
    marginTop: "10px",
  },
  border: {
    borderBottom: "2px solid #E4E8EE",
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  profilePicturePadding: {
    justifyContent: "center",
    padding: "24px 16px 24px 16px",
  },
  subtitle: {
    fontSize: remCalc(14),
    fontFamily: Bold,
    color: theme.typography.color.secondary,
  },
  subCardtitle:{
    fontSize: remCalc(12),
    fontFamily: Bold,
    color: theme.typography.color.Tertiary,
    textTransform: "uppercase",
    marginLeft: "16px"
  }
}));
// const InitialState = {
//   vechicle_number: "",
//   vechicle_type: "",
//   checkOut: "",
//   img: ""
// };
const ParkingSlotDetails = (props) => {
  const { t = () => false } = props
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const [viewDetail, setViewDetail] = React.useState();
  const [drawer, setDrawer] = React.useState(false);
  const [detail, setDetail] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const [url, setUrl] = React.useState("");
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState("");
  const [enums, setEnums] = React.useState({
    options: [],
  })
  const [enumValue, setEnumValue] = React.useState({
    type: [],
    status: [],
    objectKey: {}

  })
  //openImageViewer
  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  //closeImageViewer
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const getPassDetails = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_id: passId,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetail(response?.data?.data?.[0]);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });

      });
  };
  React.useEffect(() => {
    getPassDetails();
    // eslint-disable-next-line
  }, []);
  const viewDetails = (data) => {
    setDrawer(true);
    const InitialState = {
      vechicle_number: data?.vechicle_number,
      vechicle_type: [{ label: data?.vechicle_type }],
      checkOut: data?.actual_out,
      img: data?.vehicle_image,
    };
    setViewDetail(InitialState);
  };
  //options
  const getEnum = async () => {
    const result2 = await enumSelect([enumTypes?.vechicle_type])
    setEnums({
      options: result2?.vechicle_type,
    })
    const result = await enumSelect([enumTypes?.security_status_enum_type, enumTypes?.security_request_type])
    let requestSourceMap = result?.security_request_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let requestSourceMapStatus = result?.security_status_enum_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus)?.map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let options = {
      filterKey: requestTypeStatus?.type5,
      replaceKey: "In Progress",
      actualKey: requestTypeStatus?.type1,
      data: result?.security_status_enum_type
    }
    let status = customEnumModifier(options)
    setEnumValue({ ...enumValue, status: status, type: requestType, objectKey: requestTypeStatus })
  }
  React.useEffect(() => {
    getEnum()
    //eslint-disable-next-line
  }, [])
  const uploadS3 = async (data) => {
    let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantid}` }, alert, allowed_file_size)
    if (uploaded_file?.[0]?.url) {
      setUrl(uploaded_file?.[0]?.url);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Something Went Wrong"),
      });
    }
  };
  const VechicleCheckOut = () => {
    const payload = {
      id: passId,
      update: {
        parking_check_out_by: userProfileId,
        actual_out: new Date().toISOString(),
      },
    };

    NetworkCall(
      `${config?.api_url}/queries/security_request/updateByID`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      let parkingId = res?.data?.data?.allocated_parking_slot;
      ParkingSlot(parkingId);
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };
  //parking slot
  const ParkingSlot = (id) => {
    const payload = {
      id,
      update: {
        is_active: false,
      },
    };

    NetworkCall(
      `${config?.api_url}/queries/parking_slot/update`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then(() => {
      getPassDetails();
      setDrawer(false);
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };
  const UpdateVehicleDetailsWhileCheckOut = () => {
    let currentTime = new Date().toISOString();
    let updatedBy = userProfileId;
    const payload = {
      id: passId,
      update: {
        vechicle_number: viewDetail?.vechicle_number,
        vechicle_type: viewDetail?.vechicle_type?.[0]?.label,
        vehicle_image: viewDetail?.img ? viewDetail?.img : url,
        updated_at: currentTime,
        updated_by: updatedBy,
      },
    };

    NetworkCall(
      `${config?.api_url}/queries/security_request/updateByID`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then(() => {
      VechicleCheckOut();
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };
  const renderForm = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("View Vehicle Details")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
        </Box>
        <div
          style={{
            padding: "12px",
            height: size.height - 200,
            overflow: "scroll",
          }}
        >
          <Box
            p={1}
            className={classes.stack}
            display="flex"
            justifyContent="space-around"
          >
            <Box p={1}>
              {/* <Avatar sx={{ width: 90, height: 90 }}>
                <img
                  src={viewDetail?.img}
                  alt=""
                  className={classes.userimg}
                />
              </Avatar> */}
              <Grid container className={classes.profilePicturePadding}>
                <Avatar
                  variant="circular"
                  style={{
                    height: "128px",
                    width: "128px",
                    border: "2px solid #ffffff",
                  }}
                >
                  <img
                    height={128}
                    width={128}
                    src={
                      viewDetail?.img
                        ? generateImageUrl(viewDetail?.img, img_size.small_square)
                        : url !== ""
                          ? generateImageUrl(url, img_size.small_square)
                          : "/images/profile_image.png"
                    }
                    alt="profile_image"
                  />
                </Avatar>
                <label htmlFor="uplodebtn">
                  <Avatar
                    variant="circular"
                    style={{
                      backgroundColor: "#E4E8EE",
                      border: "3px solid #ffffff",
                      marginTop: "90px",
                      marginLeft: "-25px",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      style={{
                        height: "12px",
                      }}
                      src="/images/icons8-camera.svg"
                      alt=""
                    />
                  </Avatar>
                </label>
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="uplodebtn"
                  onChange={(value) => {
                    uploadS3(value.target.files[0]);
                    // setProfileImage(value.target.files[0]);
                  }}
                />
              </Grid>
            </Box>
          </Box>
          <Box p={1.5}>
            <div className={classes.input}>
              <SelectBox
                label={t("Vechicle Type")}
                value={viewDetail?.vechicle_type}
                options={enums?.options}
                onChange={(value) => setViewDetail("vechicle_type", value)}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                label={t("Vechicle No")}
                value={viewDetail?.vechicle_number}
                onChange={(e) =>
                  setViewDetail("vechicle_number", e.target.value)
                }
              />
            </div>
          </Box>
        </div>
        <div style={{ padding: "12px", borderTop: "2px solid #E4E8EE" }}>
          <SlideButton
            mainText={`${t("Swipe to  Check Out")}`}
            overlayText={t("Checked Out")}
            classList={"my-class2"}
            caretClassList={"my-caret-class2"}
            overlayClassList={"my-overlay-class2"}
            onSlideDone={UpdateVehicleDetailsWhileCheckOut}
            disabled={viewDetail?.checkOut ? true : false}
          />
          {/* <Button
            className={viewDetail?.checkOut ? classes.sucess : classes.warning}
            color="warning"
            variant="contained"
            fullWidth
            disabled={viewDetail?.checkOut ? true : false}
            onClick={UpdateVehicleDetailsWhileCheckOut}
            size="large"
          >
            Check Out
          </Button> */}
        </div>
      </>
    );
  };

  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      <TitleBar
        text={t("Parking Slot")}
        // color="#5078E1"
        goBack={() => history.goBack()}
      />
      <div
        className={classes.root}
        style={{ height: size.height - 60, overflow: "scroll", }}
      >
        <div className={classes.cardContent}>
          <Box display="flex" alignItems="center">
            <Box className={classes.img}>
              <img
                src="/images/pass.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginInlineStart="14px">
              <Typography className={classes.title}>
                {detail?.request_type === "VGR" && t("Visitor Gate Pass")}
                {detail?.request_type === "WGR" && t("Worker Gate Pass")}
                {detail?.request_type === "PSR" && t("Visitor Parking Pass")}
              </Typography>
              <Typography className={classes.sub}>
                {detail?.request_no}
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("PARKING SLOT")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                {detail?.slot_name}
              </Typography>

              <Typography variant="subtitle2" className={classes.heading}>
                {t("GATE ENTRY")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                {detail?.gate}
              </Typography>

              {/* <Typography variant="subtitle2" className={classes.heading}>
                PARKING START & END DATE
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                {moment(detail?.request_from)
                  .tz(moment.tz.guess())
                  .format("DD MMM   hh:mm:a")}
                &nbsp;-&nbsp;
                {moment(detail?.request_to)
                  .tz(moment.tz.guess())
                  .format("DD MMM   hh:mm:a")}
              </Typography> */}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("UNIT")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                {detail.unit?.[0]?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("PARKING START & END DATE")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                &#x202a;{moment(detail?.request_from)
                  .tz(moment.tz.guess())
                  .format("DD MMM   hh:mm:a")}&#x202c;
                &nbsp;-&nbsp;
                &#x202a;{moment(detail?.request_to)
                  .tz(moment.tz.guess())
                  .format("DD MMM   hh:mm:a")}&#x202c;
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: "14px 4px 8px 8px" }}>
          <Typography className={classes.subCardtitle}>{t("VEHICLE DETAILS")}</Typography>
          <Box height="10px" />
          <>
            <VehicleCard
              data={detail}
              onClick={viewDetails}
              Reload={() => getPassDetails()}
              closeImageViewer={closeImageViewer}
              openImageViewer={openImageViewer}
              isViewerOpen={isViewerOpen}
              currentImage={currentImage}
              statuskey={enumValue?.type}
              pendingStatus={enumValue?.objectKey}

            />
            <Box height="10px" />
          </>
        </div>
      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("parkingSlotDetails")(ParkingSlotDetails)