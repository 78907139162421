import { Stack, Typography } from '@mui/material'
import React from 'react'
import { PickupDeliveryCardStyle } from './style'

export const PickupDeliveryCard = ({
    count = "0",
    title = "",
    logo,
    handleClick = () => false
}) => {
    const classes = PickupDeliveryCardStyle()
    return (
        <Stack onClick={handleClick}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography className={classes.title}>{count}</Typography>
                {logo}
            </Stack>
            <Typography className={classes.subTitle}>{title}</Typography>
        </Stack>
    )
}