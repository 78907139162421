import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Delivery from "./delivery";
import GatePass from "./gatePass";
import Parking from "./parking";
import SwiftInOut from "./swiftInOut";
import WalkInOut from "./walkIn";
import { useLocation ,useHistory} from "react-router-dom";
import { TitleBar } from "../../components";
import { Routes } from "../../router/routes";
import { Container } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ListingEntries = ({ selectedType, text }) => {
  const classes = useStyles();
  const searchURL = useLocation().search;
  const location = useLocation();
  const type = new URLSearchParams(searchURL).get("type");
  const history=useHistory()


  const title={
    "1":"Gate Passes",
    "2":"Parking Slot",
    "3":"Delivery Collection",
    "4":"Shift In & Out",
    "5":"Visitor Entry Request"
  }

  const goBack=()=>{
    history.push(Routes.gatePass)
  }
  

  return (
    <Container maxWidth="sm" style={{padding:"0px"}} className={classes.root}>
      <TitleBar text={title[type]} goBack={goBack}/>
      {type === "1" && <GatePass locationState={location?.state?.filterData} />}
      {type === "2" && <Parking locationState={location?.state?.filterData} />}
      {type === "3" && <Delivery />}
      {type === "4" && <SwiftInOut />}
      {type === "5" && <WalkInOut />}
    </Container>
  );
};

export default ListingEntries;