import {
  Badge,
  Box,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LoaderSimmer } from "../../components";
import Filter from "../../components/filter";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, Bold, customEnumModifier, enumSelect, enumTypes, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods, remCalc, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import InfiniteScroll from "react-infinite-scroll-component";
import { EntriesCard } from "./components/entriesCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  loader: {
    textAlign: "center",
    marginTop: "50%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  noDataBox: {
    height: `calc(100vh - 60px)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noDataText: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.tertiary
  }
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const Parking = (props) => {
  const { t = () => false } = props;
  const { state } = useLocation();
  const classes = useStyles();
  const size = useWindowDimensions();
  const [list, setList] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [property, setProperty] = React.useState([]);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [filter, setFilter] = React.useState(false);
  const [unitList, setUnitList] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(true);
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
  const [enumValue, setEnumValue] = React.useState({
    type: [],
    status: [],
    objectKey: {}

  })

  const getListFromdashboard = (offset = 0, filter = true) => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/security-dashboard/${state?.type}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setList(response?.data?.data);
        } else {
          setList(list?.concat(response?.data?.data))
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const getPassList = (val, requestType = ["PSR"], status, offset = 0, filter = true, dateFilterr = false, dateValue = false) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: requestType,
      status: status,
      entries: true,
      is_new_entries: true,
      property_id: dateFilterr ? undefined : val,
      unit_id: dateFilterr ? val : undefined,
      entry_check_in_start_date: dateFilter?.startDate?.toString()?.length > 0 ? new Date(dateFilter?.startDate).toISOString() : undefined,
      entry_check_in_end_date: dateFilter?.endDate?.toString()?.length > 0 ? new Date(dateFilter?.endDate).toISOString() : undefined,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setFilter(false)
        // let dataList = response?.data?.data
        //   // .filter((val) => val.actual_in !== null && val.actual_out === null)
        //   ?.map((val) => {
        //     return {
        //       id: val?.id,
        //       title: val?.request_no,
        //       actual_out: val?.actual_out,
        //       actual_in: val?.actual_in,
        //       subtitle1: val?.vechicle_type,
        //       subtitle2: val?.unit?.[0]?.unit_no,
        //       img: "/images/pass.svg",
        //     };
        //   });
        if (filter) {
          setList(response?.data?.data);
        } else {
          setList(list?.concat(response?.data?.data))
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const payload = { roleID: roleId, limit: null };

    NetworkCall(
      `${config?.api_url}/queries/access_control/property_list`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      let data = response?.data?.data?.access_control?.map((val) => val?.property?.value)
      setProperty(data);

      if (data?.length > 0) {
        getPassList(data, ["PSR"], ["Rejected", "Pending", "Approved"]);
      }
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };

  //filter json

  const filterData = [
    // {
    //   id: "1",
    //   title: "Type",
    //   key: "type",
    //   showSearch: true,
    //   filterType: "CHECKBOX",
    //   values: [
    //     {
    //       label: "Worker Gate Pass",
    //       value: "WGR",
    //     },
    //     {
    //       label: "Visitor Gate Pass",
    //       value: "VGR",
    //     },
    //   ],
    // },
    {
      id: "2",
      title: t("Status"),
      key: "status",

      filterType: "CHECKBOX",
      values: enumValue?.status ?? [],
    },
    {
      id: "3",
      title: t("Unit"),
      key: "unit",
      filterType: "CHECKBOX",
      values: unitList,
    },
    {
      id: "4",
      title: t("Date"),
      key: "date",
      filterType: "new_date",
      values: [],
    },
  ];
  const filterUnits = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const payload = { roleID: roleId, limit: null };

    NetworkCall(
      `${config?.api_url}/queries/access_control/property_list`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      setUnitList(response?.data?.data?.access_control[0]?.property?.units);
      const data = response?.data?.data?.access_control[0]?.property?.units?.map((e)=>e?.value)
      getPassList(data, ["PSR"], ["Rejected", "Pending", "Approved"], 0, true, state?.filterData?.date)
      setSelectedFilter({
        ...selectedFilter,
        unit: data
      })
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };
  //apply filter
  const applyFilter = (data) => {
    // setOffset(0);
    setSelectedFilter(data);

    // if (data?.type.length > 0) {
    if (data?.unit.length > 0) {
      setSelectedFilter(data);
      getPassList(
        data.unit,
        ["PSR"],
        data.status.length > 0 ? data?.status : ["Approved"],
        0,
        true,
        true,
        dateFilter
      );


    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please Select Unit"),
      });
    }
    // } else {
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please Select Type",
    //   });
    // }
  };
  //enum
  const getEnum = async (data) => {
    const result = await enumSelect([enumTypes?.security_status_enum_type, enumTypes?.security_request_type])
    let requestSourceMap = result?.security_request_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let requestSourceMapStatus = result?.security_status_enum_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus)?.map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let options = {
      filterKey: "Received At Gate",
      replaceKey: "In Progress",
      actualKey: "In Progress",
      data: result?.security_status_enum_type?.filter(_ => !["Received At Gate", "Cancelled"].includes(_?.value)),
    }
    let status = customEnumModifier(options)
    setEnumValue({ ...enumValue, status: status, type: requestType, objectKey: requestTypeStatus })

  }
  const fetchMoreData = () => {
    setOffset(offset + 10);
    if(Object.keys(state?.filterData)?.length > 0){
      getPassList(selectedFilter?.unit, ["PSR"], ["Rejected", "Pending", "Approved"], offset + 10, false, dateFilter)
    } else if (state?.type === "parking" || state?.type === "overStayParking") {
      getListFromdashboard(
        offset + 10,
        false
      );
    } else {
      getPassList(property, ["PSR"],
        [
          "Rejected",
          "Pending",
          "Approved"
        ],
        offset + 10,
        false
      );
    }
  };
  React.useEffect(() => {
    if (clientId) {

      getEnum()
      if (state?.type === "parking" || state?.type === "overStayParking") {
        getListFromdashboard();
      } else {
        getPropertyId();
      }

      filterUnits();
    }

    setSelectedFilter({
      ...selectedFilter
    })

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <LoaderSimmer count={10} />

      ) : (
        <div className={classes.root}>
          {
            list?.length > 0 ?
              <Box>
                <Box display="flex" alignItems="center" p={1.5}>
                  <Box flexGrow={1}>
                    {/* <TextField
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Search Parking Slot"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              height: "30px",
            }}
            fullWidth
            className={classes.search}
          /> */}
                    <Typography className={classes.title}>
                      {t("Parking_Slot")} ({list?.length})
                    </Typography>
                  </Box>
                  <Box marginLeft="10px">
                    <IconButton
                      className={classes.filter}
                      onClick={() => setFilter(true)}
                    >
                      <Badge variant="dot" color="primary">
                        <img src="/images/icons8-funnel.svg" alt="filter" />
                      </Badge>
                    </IconButton>
                  </Box>
                </Box>
                <InfiniteScroll
                  dataLength={list?.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 110}
                >
                  <Box p={1.5}>
                    {list?.map((val) => (
                      <Box marginBottom={"10px"}>
                        {/* <ParkingCard data={val} type={"Parking"} /> */}
                        <EntriesCard
                          id={val?.id}
                          imgUrl={generateImageUrl(val?.vehicle_image, img_size.small_square)}
                          title={"Parking Slot Requirement"}
                          vehicleType={val?.vechicle_type}
                          count={val?.vechicle_number}
                          propertyName={val?.property_name}
                          unitNo={val?.unit?.[0]?.unit_no}
                          startDateString={`${t("In")} -`}
                          startDate={val?.request_from}
                          endDateString={`${t("Out")} -`}
                          endDate={val?.request_to}
                          type="parking"
                          status={val?.status}
                          selectedTab="parking"
                        />
                      </Box>
                    ))}
                  </Box>
                </InfiniteScroll>
              </Box>
              :
              <Box className={classes.noDataBox}>
                <Typography className={classes.noDataText}>No Data Found</Typography>
              </Box>
          }
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={filter}
              onClose={() => {
                setFilter(false)
                setdateFilter({
                  startDate: "",
                  endDate: ""

                })
              }}
            >
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  // type: selectedFilter.type ?? enumValue?.type?.map(i => i.label),
                  status: selectedFilter.status ?? enumValue?.status?.map(i => i.label),
                  unit: selectedFilter.unit ?? unitList?.map(i => i.value),
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                onApply={applyFilter}
                onClose={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                updateState={updateState}
                data={dateFilter}
              />
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer open={filter} onClose={() => {
              setFilter(false)
              setdateFilter({
                startDate: "",
                endDate: ""

              })
            }}>
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  // type: selectedFilter.type ?? enumValue?.type?.map(i => i.label),
                  status: selectedFilter.status ?? enumValue?.status?.map(i => i.label),
                  unit: selectedFilter.unit ?? unitList?.map(i => i.value),
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                onApply={applyFilter}
                onClose={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                updateState={updateState}
                data={dateFilter}
              />
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default withNamespaces("gatePass")(Parking);
