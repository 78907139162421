import { Avatar, AvatarGroup, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
// import ImageViewer from "react-simple-image-viewer";
import { DialogDrawer, SlideButton } from "../../components";
import { withNamespaces } from "react-i18next";
import { Bold, Regular, SemiBold, generateImageUrl, img_size } from "../../utils";
import Slider from "react-slick";
// import { Slider } from "../../components/slider";


const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },

  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },

  sub: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "0.75rem",
    color: "#5078E1",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
  checksub: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary,
  },
  checksub2: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.palette.primary.main
  },
  checkheading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    // marginTop: "10px",
  },
  bottom: {
    padding: "12px",
  },
  span: {
    backgroundColor: "#5078E11E",
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: Bold,
    marginLeft: "6px",
    padding: "4px",
    borderRadius: "4px"

  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    }
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    }
  },
  profileicon: {
    fontSize: "8.0rem",
    padding: "14px",
  },
  avatarGroup: {
    '& .MuiAvatar-root': {
      marginRight: "-8px"
    }
  }
}));

const ServiceVendorCard = (props) => {
  const { t = () => false, currentImage =[] } = props
  const classes = useStyles(props);
  const language = localStorage.getItem("i18nextLng")
  // const disableButton = ((new Date() <= new Date(props?.parentData?.request_from)) || (new Date() >= new Date(props?.parentData?.request_to))) ? true : false
  console.log("props?.currentImage ==>", props?.currentImage)

  const nudge_slide_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    cssEase: "linear",
    arrows: true,
  };

  return (
    <>
      <div className={classes.card}>
        {props?.data?.visitor_type === "VE" &&
          <>
            <Box alignItems="center" display="flex" p={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <AvatarGroup className={language === "ar" && classes.avatarGroup}>
                  {props?.data?.vendor_image ?
                    <Avatar src={props?.data?.vendor_image ? generateImageUrl(props?.data?.vendor_image, img_size.small_square) : ""} onClick={() => props?.openImageViewer([props?.data?.vendor_image])}>
                    </Avatar>
                    :
                    <Avatar>
                    </Avatar>
                  }
                  {props?.data?.vehicle_image ?
                    <Avatar src={props?.data?.vehicle_image ? generateImageUrl(props?.data?.vehicle_image, img_size.small_square) : ""} onClick={() => props?.openImageViewer([props?.data?.vehicle_image])}>
                    </Avatar>
                    :
                    <Avatar>
                    </Avatar>
                  }
                </AvatarGroup>
                <Typography variant="subtitle2" sx={{ marginRight: language === "ar" && "16px" }} className={classes.checksub}>
                  {t("Vendor & Vehicle Images")}
                  {(props?.data?.vendor_image?.length > 0 || props?.data?.vehicle_image?.length > 0) &&
                    <Typography
                      variant="subtitle2"
                      className={classes.checksub2}
                      onClick={() => props?.openImageViewer([props?.data?.vendor_image, props?.data?.vehicle_image])}
                    >
                      {t("View Images")}
                    </Typography>
                  }
                </Typography>

                {/* {props?.isViewerOpen && (
                  <ImageViewer
                    src={props?.currentImage}
                    currentIndex={0}
                    onClose={props?.closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                      backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside={true}
                  />
                )} */}
                {
                  <DialogDrawer
                    open={props?.isViewerOpen}
                    onClose={props?.closeImageViewer}
                    header={"View"}
                    component={
                      <div>
                        {
                          currentImage?.length > 0 &&
                          <Slider {...nudge_slide_settings}>
                            {currentImage?.map((data, i) => {
                              return <img src={data?.url} alt="" style={{ width: "100%", height: "450px" }} />
                            })}
                          </Slider>
                        }
                      </div>
                    }
                  />
                }
              </Stack>
            </Box>
            <Divider />
          </>


        }
        <Grid container spacing={0.5} className={classes.bottom}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.checkheading}>
              {t("Checked In")}
            </Typography>
            {props?.data?.check_in_time ?
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.check_in_byByID?.first_name && "By " + props?.data?.check_in_byByID?.first_name + ","}&nbsp;
                &#x202a;{props?.data?.check_in_time ? moment(props?.data?.check_in_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}&#x202c;
              </Typography> : " - "
            }
          </Grid>
          <Grid item xs={12} mt={0.5}>
            <Typography variant="subtitle2" className={classes.checkheading}>
              {t("Checked Out")}
            </Typography>
            {props?.data?.check_out_time ?
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.check_out_byByID?.first_name && "By " + props?.data?.check_out_byByID?.first_name + ","}&nbsp;
                &#x202a;{props?.data?.check_out_time ? moment(props?.data?.check_out_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}&#x202c;
              </Typography> : " - "
            }
          </Grid>
          <Grid item xs={12} mt={1.5}>
            <SlideButton
              mainText={`${props?.data?.check_in_time === null && props?.data?.check_out_time === null ? t("Swipe to Check In") : t("Swipe to Check Out")}`}
              overlayText={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? t("Checked In") : t("Checked Out")}
              classList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-class1" : "my-class2"}
              caretClassList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-caret-class1" : "my-caret-class2"}
              overlayClassList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-overlay-class1" : "my-overlay-class2"}
              onSlideDone={() => props?.data?.check_in_time ? props.onClick(props.data) : false}
              disabled={props?.data?.check_in_time && props?.data?.check_out_time}
            />
            {/* <Button
              fullWidth
              variant="contained"
              onClick={() => props?.data?.check_in_time ? props.onClick(props.data) : false}
              className={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? classes.sucess : classes.warning}
              disabled={props?.data?.check_in_time && props?.data?.check_out_time}
              size="large"
              loading={props?.loading}
              loadingPosition="end"
            >
              {props?.data?.check_in_time === null && props?.data?.check_out_time === null ? " Check In" : "Check Out"}
            </Button> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default withNamespaces("serviceVendorDetails")(ServiceVendorCard)