import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderSimmer, TextBox, TitleBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ServiceVendorCard from "./serviveVendorCard";
import { AlertProps, Bold, ExtraBold, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods, Regular, SemiBold } from "../../utils";
import { AlertContext } from "../../contexts";
import { withNamespaces } from "react-i18next";



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    margin: "12px 10px 8px 14px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: Regular,
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  subtitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: Bold,
    paddingLeft: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  userimg: {
    borderRadius: "50%",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  stack: {
    backgroundColor: "#F5F7FA",
    margin: "12px",
  },
  stack1: {
    borderRight: "1px solid #E4E8EE",
  },
  preview: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.Tertiary,
    textAlign: "center",
    marginBottom: "8px",
  },
  input: {
    marginTop: "10px",
  },
  border: {
    borderBottom: "2px solid #E4E8EE",
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
}));
const InitialState = {
  name: "",
  mobile: "",
  mail: "",
  idType: "",
  idno: "",
};
const ServiceVendorDetails = ({
  t = () => false
}) => {
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const [viewDetail] = React.useState({ ...InitialState });
  const [drawer, setDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [detail, setDetail] = React.useState([]);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)

  const [unit, setUnit] = React.useState([]);
  const [swiftId, setSwiftId] = React.useState("");
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState("");
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const alert = React.useContext(AlertContext);


  //openImageViewer
  const openImageViewer = React.useCallback((index) => {
    let constructedAssets = index?.map((e)=>{
      return {
        url: e
      }
    })
    setCurrentImage(constructedAssets);
    setIsViewerOpen(true);
  }, []);
  //closeImageViewer
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getPassDetails = () => {
    setLoading(true)
    const payload = { id: passId };

    NetworkCall(
      `${config?.api_url}/queries/swift_in_out_log/getByID`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      setSwiftId(response?.data?.data?.swift_in_out_log[0]?.id);
      setDetail(response?.data?.data?.swift_in_out_log[0]);
      setUnit(response?.data?.data?.swift_in_out_log_units);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
      setLoading(false);
    })
  };
  React.useEffect(() => {
    getPassDetails();
    // eslint-disable-next-line
  }, []);

  // const viewDetails = (data) => {
  //   setDrawer(true);
  //   const InitialState = {
  //     vechicle_number: data?.vechicle_number,
  //     vechicle_type: data?.vechicle_type,
  //     checkOut: data?.actual_out,
  //   };
  //   setVieDetail(InitialState);
  // };

  const checkOut = () => {
    const payload = {
      id: swiftId,
      check_out_by: userProfileId,
      check_out_time: new Date().toISOString(),
      updated_by: userProfileId,
      updated_at: new Date().toISOString(),
      event:"Check-Out",
    }
    NetworkCall(
      `${config.api_url}/security/upsert_swift_in_out_logs`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: t("checked Out"),
        });
        getPassDetails();

      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });

      });
  };

  const renderForm = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("View Vehicle Details")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
        </Box>
        <div
          style={{
            padding: "12px",
            height: size.height - 200,
            overflow: "scroll",
          }}
        >
          <Box
            p={1}
            className={classes.stack}
            display="flex"
            justifyContent="space-around"
          >
            <Box p={1}>
              <Avatar sx={{ width: 90, height: 90 }}>
                <img
                  src={generateImageUrl(viewDetail?.image, img_size.small_square)}
                  alt=""
                  className={classes.userimg}
                />
              </Avatar>
            </Box>
          </Box>
          <Box p={1.5}>
            <div className={classes.input}>
              <TextBox
                label={t("Vechicle Type")}
                disabled
                value={viewDetail?.vechicle_type}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                label={t("Vechicle No")}
                disabled
                value={viewDetail?.vechicle_number}
              />
            </div>
          </Box>
        </div>
        <div style={{ padding: "12px", borderTop: "2px solid #E4E8EE" }}>
          <Button
            className={viewDetail?.checkOut ? classes.warning : classes.sucess}
            variant="contained"
            fullWidth
            disabled={viewDetail?.checkOut ? true : false}
            size="large"
          // onClick={VechicleCheckOut}
          >
            {t("Check Out")}
          </Button>
        </div>
      </>
    );
  };
  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      {detail?.visitor_type === "DH" && (
        <TitleBar
          text={t("Domestic Helper")}
          // color="#5078E1"
          goBack={() => history.goBack()}
        />
      )}
      {detail?.visitor_type === "SP" && (
        <TitleBar
          text={t("Service Provider")}
          // color="#5078E1"
          goBack={() => history.goBack()}
        />
      )}
      {detail?.visitor_type === "VE" && (
        <TitleBar
          text={t("Vendor Entry")}
          // color="#5078E1"
          goBack={() => history.goBack()}
        />
      )}
      <div
        className={classes.root}
        style={{ height: size.height - 60, overflow: "scroll" }}
      >
        {loading ?
          <LoaderSimmer count={10} />
          :
          <>
            <div className={classes.cardContent}>
              <Box
                display="flex"
                alignItems="center"
                borderBottom={"1.5px dashed #E4E4E4"}
                pb={1}
              >
                <Box className={classes.img}>
                  {detail?.visitor_type === "SP" ? (
                    <img
                      src="/images/service.svg"
                      alt=""
                      style={{ objectFit: "contain", marginTop: "4px" }}
                    />
                  ) : (
                    <img
                      src="/images/domestic.svg"
                      alt=""
                      style={{ objectFit: "contain", marginTop: "4px" }}
                    />
                  )}
                </Box>
                <Box flexGrow={1} marginInlineStart="14px">
                  <Typography className={classes.title}>
                    {detail?.visitor_type === "DH" && t("Domestic Helper")}
                    {detail?.visitor_type === "SP" &&
                      detail?.service_providers_master?.name}
                    {detail?.visitor_type === "VE" && detail?.vendor_master?.name}
                  </Typography>
                </Box>
              </Box>
              {detail?.visitor_type === "VE" && (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("REFERENCE ID")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {detail?.reference_id ? detail?.reference_id : " - "}
                    </Typography>
                    {/* <br /> */}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("VENDOR PERSON NAME")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {detail?.person_name && detail?.person_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("UNIT")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {unit?.length > 0 ? unit?.map((val) => val?.unit?.name).join(',') : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("GATE ENTRY")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {detail?.access_gates_master?.name}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {detail?.visitor_type === "SP" && (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("REFERENCE ID")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {detail?.reference_id ? detail?.reference_id : " - "}
                    </Typography>
                    {/* <br /> */}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("PROPERTY")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {detail?.property?.name && detail?.property?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("SERVICE PERSON NAME")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {detail?.service_providers_master?.name &&
                        detail?.service_providers_master?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("GATE ENTRY")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      {detail?.access_gates_master?.name}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
            <div style={{ margin: "8px 4px 8px 8px" }}>
              {detail?.visitor_type === "VE" && (
                <Typography className={classes.subtitle}>{t("VENDOR IMAGES")}</Typography>
              )}
              <Box height="10px" />
              <>
                <ServiceVendorCard
                  data={detail}
                  onClick={checkOut}
                  closeImageViewer={closeImageViewer}
                  openImageViewer={openImageViewer}
                  isViewerOpen={isViewerOpen}
                  currentImage={currentImage}
                />
                <Box height="10px" />
              </>
            </div>
          </>
        }
      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("serviceVendorDetails")(ServiceVendorDetails)