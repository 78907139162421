import React from 'react'
import { CustomSwitch, Search, TitleBar } from '../../components'
import { useHistory } from 'react-router-dom'
import { Box, Container, Stack } from '@mui/material'
import { CallCard } from './components/callCard'

export const AudioVideoCall = () => {
    const history = useHistory()
    const [callData, setCallData] = React.useState({
        selectedList: { label: "Units", value: "Units" },
        searchText: ""
    })

    const list = [
        { label: "Units", value: "Units" },
        { label: "Guards", value: "Guards" }
    ]

    const updateState = (key, value) => {
        setCallData({ ...callData, [key]: value })
    }

    return (
        <Container maxWidth="sm" sx={{ padding: 0 }}>
            <TitleBar text={"Call"} goBack={() => history.goBack(-1)} />
            <Box style={{ backgroundColor: "#F2F5FA", height: "calc(100vh - 55px)" }}>
                <Box p={"12px 16px"}>
                    <CustomSwitch
                        list={list}
                        selected={callData?.selectedList}
                        onChange={(data) => updateState("selectedList", data)}
                    />
                </Box>
                <Box p={"0px 16px"}>
                    <Search
                        value={callData?.searchText}
                        onChange={(value) => updateState("searchText", value)}
                        borderRadius={"8px"}
                    />
                </Box>
                <Box p={"12px 16px 0px 16px"}>
                    <Stack rowGap={"12px"}>
                        <CallCard />
                        <CallCard />
                        <CallCard />
                    </Stack>
                </Box>
            </Box>
        </Container>
    )
}