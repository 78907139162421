import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PurpleUnit from "../../../assets/purpleUnit";
import SearchIcon from "../../../assets/searchIcon";
import { useStyles } from "./styles";
import { LoaderSimmer, SelectBox, TextBox } from "../../../components";
import { NoData, SearchUnit } from "../../../assets"
import React from "react";
import { UnitCard } from "./unitCard"
import { NetWorkCallMethods, remCalc } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { loadOptionsApis } from "../../../utils/loadOptions";
import InfiniteScroll from "react-infinite-scroll-component";

export const ChooseUnit = ({ t = () => false, setStep = () => false, data = {}, updateState = () => false, setData = () => false }) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        search: "",
        is_search: false,
        drawer: false,
        property: null,
        units: [],
        offset: 0,
    });
    const [is_search, setIsSearch] = React.useState(false)
    const [loading, setIsLoading] = React.useState(true)



    const getPropertyId = () => {

        NetworkCall(`${config.api_url}/walkInout/get_property_unit`, NetWorkCallMethods.post, {}, null, true, false)
            .then((response) => {
                const data = response?.data?.data;

                let property = {
                    ...data,
                    address: `${data?.city ? `${data?.city},` : ""}${data?.state ? `${data?.state},` : ""}${data?.country ? `${data?.country},` : ""}`
                }

                getUnits(0, null, property)

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getUnits = (offset = 0, search, property, more = false) => {

        const payload = {
            offset,
            limit: 10,
            search
        }

        NetworkCall(`${config.api_url}/walkInout/get_walk_in_units`, NetWorkCallMethods.post, payload, null, true, false)
            .then((response) => {
                const data = response?.data?.data;
                setState({
                    ...state,
                    units: more ? [...state?.units, ...data] : data,
                    property: property ?? state?.property,
                    search: search,
                    offset
                })
                if (!search || more) {
                    setIsLoading(false)
                } else {
                    setIsSearch(false)
                }

            })
            .catch((err) => {
                console.log(err);
            });


    }

    React.useEffect(() => {
        getPropertyId()

        // eslint-disable-next-line
    }, [])

    const isFormValid = () => {

        let isValid = true;
        let error = data.error;

        if (data.gate.length === 0) {
            isValid = false;
            error.gate = t("Gate is Required")
        }
        setData({ ...data, error });

        return isValid;
    };

    const onChooseUnit = (unit,user) => {

        if (isFormValid()) {
            setStep(2)
            updateState("unit", {...unit,user})
        }
    }

    const onSearch = (search) => {
        setIsSearch(true)

        getUnits(0, search)
    }

    const fetchMoreData = () => {
        let offset = state?.offset + 10;
        getUnits(offset, state?.search, null, true)
    }

    return (
        <Box className={classes.chooseUnitRoot}>

            {/* unit Card */}
            <Box className={classes.unitBox}>
                {
                    loading ?
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            style={{ borderRadius: "4px" }}
                        />
                        :
                        <Box className={classes.purpleBox}>

                            <Stack alignItems={"center"} direction={"row"}>
                                <PurpleUnit />
                                <Stack marginInlineStart={"12px"} direction={"column"} flexGrow={1}>
                                    <Typography className={classes.text}>{state?.property?.name}</Typography>
                                    <Typography className={classes.subText}>{state?.property?.address}</Typography>
                                </Stack>
                                {/* <Typography className={classes.change}>{t("Change")}</Typography> */}
                            </Stack>
                        </Box>
                }


                <Box marginTop="10px">
                    <SelectBox
                        isPaginate
                        fontSize={remCalc(14)}
                        value={data?.gate}
                        isError={data?.error?.gate?.length > 0}
                        errorMessage={data?.error?.gate}
                        onChange={(value) => updateState("gate", value)}
                        placeholder={t("Select Gate")}
                        loadOptions={(search, array, handleLoading) =>
                            loadOptionsApis(
                                "walkInout/get_accessed_gate",
                                { "search": search },
                                search,
                                array,
                                handleLoading,
                                "data"
                            )
                        }
                    />
                </Box>
            </Box>

            <Box padding="12px" >
                {/* choose Unit */}
                <TextBox
                    label={null}
                    textBoxBgColor="white"
                    fontSize={remCalc(14)}
                    onSearch={(e) => onSearch(e.target.value)}
                    placeholder={"Search"}
                    startAdornment={<SearchIcon />}
                    onChange={(e) => onSearch(e.target.value)}
                />
                {
                    is_search ?
                        <Box display="flex" alignItems="center" marginTop="5rem" justifyContent="center">
                            <SearchUnit />
                        </Box>
                        :
                        loading ?
                            <LoaderSimmer count={10} card size={350}/>
                            :
                            <Box>
                                {
                                    state?.units?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={state?.units?.length}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            height={"calc(100vh - 285px)"}
                                        >
                                            {state?.units?.map((i) => {
                                                return <UnitCard value={data?.unit} is_open={data?.gate?.length===0} onChooseUnit={onChooseUnit} data={i} t={t} />
                                            })}
                                        </InfiniteScroll>
                                        :
                                        <center style={{ marginTop: "5rem" }}>
                                            <NoData />
                                        </center>

                                }
                            </Box>


                }
            </Box>
        </Box>
    )
}