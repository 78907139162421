import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import CallCardIcon from '../utils/callCardIcon'
import VideoCallIcon from '../utils/videoCallIcon'
import AudioCallIcon from '../utils/audioCallIcon'
import { CallCardStyle } from './style'

export const CallCard = () => {
    const classes = CallCardStyle()
    return (
        <Box className={classes.card} >
            <Stack direction={"row"} columnGap={'10px'} p={"8px 0px 12px 0px"}>
                <CallCardIcon />
                <Stack rowGap={"6px"}>
                    <Typography className={classes.title}>P-F02-U15</Typography>
                    <Typography className={classes.content}>Floor 3, P Tower, Rubix Apartment</Typography>
                </Stack>
            </Stack>
            <Divider />
            <Stack direction={"row"} justifyContent={"space-evenly"}
                divider={<Divider orientation="vertical" flexItem sx={{ height: "21px" }} />} p={"12px 12px 0px 12px"}>
                <Stack direction={"row"} rowGap={1} alignItems={"center"} columnGap={'9px'}>
                    <VideoCallIcon />
                    <Typography className={classes.callText}>Video Call</Typography>
                </Stack>
                <Stack direction={"row"} rowGap={1} alignItems={"center"} columnGap={'9px'}>
                    <AudioCallIcon />
                    <Typography className={classes.callText}>Audio Call</Typography>
                </Stack>
            </Stack>
        </Box>
    )
}