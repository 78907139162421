import { Avatar, Box, Container, Divider, Grid, Stack, Typography } from "@mui/material";

import React from "react";
import { DialogDrawer, TitleBar } from "../../components";
// import { LoderSimmer } from "../../components/simmers/card";
import { withNamespaces } from "react-i18next";
import { useStyles } from "./style";

import { SimpleAccordion } from "../../components/accordian";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import ManagerCard from "./components/managerCard";
import DepartmentCard from "./components/departmentCard";
import DepartmentLogo from "../../assets/departmentLogo";
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { AlertContext } from "../../contexts";
import { ViewCard } from "./components/viewCard";
import MyCompanyIcon from "../../assets/myCompany"
import MyOrganizationIcon from "../../assets/myOrganization";


export const MyOrganizations = (props) => {

    const myOrgObj = {
        myTeam: {
            accordianTitle: "My Team",
            accordianContent: [],
            key: "My Team",
        },
        myDepartment: {
            accordianTitle: "My Department",
            accordianContent: [],
            key: "My Department"
        },
        myCompany: {
            accordianTitle: "My Company",
            accordianContent: [],
            key: "My Company"
        },
        myOrg: {
            accordianTitle: "My Organization",
            accordianContent: [],
            key: "My Organization"
        }
    }

    const { t = () => false } = props;
    const classes = useStyles();
    const history = useHistory();
    // const [loading, setLoading] = React.useState(true);
    const [managerCard, setManagerCard] = React.useState(false)
    const [teamCard, setTeamCard] = React.useState(false)
    const [screenData, setScreenData] = React.useState(myOrgObj)

    const [myOrgData, setMyOrgData] = React.useState({
        profile: {},
        myCompanyExpand: false,
        myOrgExpand: false,
        viewCardBool: false,
        selectedViewData: "",
        viewReporter: false
    })

    const alert = React.useContext(AlertContext)



    const goBack = () => {
        history.goBack(-1)
    };



    const components = (item, content, index) => {
        switch (item?.key) {
            case "My Team":
                return (
                    <>
                        <ManagerCard
                            t={t}
                            title={content?.accordianTitle}
                            hidden={item?.accordianContent?.length < 5 ? item?.accordianContent?.length === index + 1 ? false : true : true}
                            open={teamCard}
                            onClick={() => handleViewData(content)}
                            onClose={() => handleViewClose()}
                            color={"red"}
                            onSelect={() => viewRedirect("myMembers", content)}
                            dialogData={myOrgData?.profile}
                            hideViewMore
                        />
                    </>

                );
            case "My Department":
                return (
                    <>
                        <DepartmentCard
                            t={t}
                            title={content?.accordianTitle}
                            hidden={item?.accordianContent?.length < 5 ? item?.accordianContent?.length === index + 1 ? false : true : true}
                            onClick={() => viewRedirect("department", content)}
                            icon={<DepartmentLogo />}
                        />
                    </>

                );
            case "My Company":
                return (
                    <>
                        {
                            <DepartmentCard
                                t={t}
                                title={content?.accordianTitle}
                                hidden={item?.accordianContent?.length < 5 ? item?.accordianContent?.length === index + 1 ? false : true : true}
                                onClick={() => setTeamCard(true)}
                                icon={<MyCompanyIcon />}
                                viewMore={true}
                                viewClick={() => handleViewData(content)}
                            />
                        }
                    </>

                );
            case "My Organization":
                return (
                    <>
                        <DepartmentCard
                            t={t}
                            title={content?.accordianTitle}
                            hidden={item?.accordianContent?.length < 5 ? item?.accordianContent?.length === index + 1 ? false : true : true}
                            onClick={() => setTeamCard(true)}
                            icon={<MyOrganizationIcon />}
                            viewMore={true}
                            viewClick={() => handleViewData(content)}
                        />
                    </>

                );
            default:
                break;
        }


    }

    // color for divider
    const dividerColor = {
        "Reporting": "#666666",
        "My Team": "#5078E1",
        "My Department": "#B3A16D",
        "My Company": "#896DB3",
        "My Organization": "#6DAFB3",
    }

    // color for divider
    const viewTexts = {
        "My Team": t("View All"),
        "My Department": t("View All Department"),
        "My Company": t("View All Company"),
        "My Organization": t("View All Organization"),
    }

    const viewRedirect = (key, data) => {
        history.push({
            pathname: Routes.myOrganizationsView,
            state: {
                viewkey: key,
                data: data
            }
        })
    }

    React.useEffect(() => {
        getMyOrg()
        getMyOrgTeam()
        // eslint-disable-next-line
    }, [])

    // const expandAccodion = (key) => {
    //     switch (key) {
    //         case "My Company":
    //             if (!myOrgData?.myCompanyExpand) {
    //                 getMyCompanyAndOrg("Company")
    //                 setMyOrgData({
    //                     ...myOrgData,
    //                     myCompanyExpand: true
    //                 })
    //             } else {
    //                 setMyOrgData({
    //                     ...myOrgData,
    //                     myCompanyExpand: false
    //                 })
    //             }
    //             break;
    //         case "My Organization":
    //             if (!myOrgData?.myOrgExpand) {
    //                 getMyCompanyAndOrg("Client")
    //                 setMyOrgData({
    //                     ...myOrgData,
    //                     myOrgExpand: true
    //                 })
    //             } else {
    //                 setMyOrgData({
    //                     ...myOrgData,
    //                     myOrgExpand: false
    //                 })
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // }

    const getMyOrg = () => {
        const payload = {
            is_initial: true
        };
        NetworkCall(
            `${config.api_url}/my_organisation`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setMyOrgData({ ...myOrgData, profile: res?.data?.data })
                getMyOrgTeam(res?.data?.data)
            })
            .catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    }

    const getMyOrgTeam = (data) => {
        const payload = {
            limit: 5,
            offset: 0
        };
        NetworkCall(
            `${config.api_url}/my_organisation/my_teams`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const constructedData = res?.data?.data?.map((e) => {
                    return {
                        accordianTitle: e?.team_name,
                        ...e
                    }
                })
                const companyConstructedData = data?.company?.data?.map((e) => {
                    return {
                        accordianTitle: e?.contact_name,
                        ...e
                    }
                })

                const orgConstructedData = data?.organization?.data?.map((e) => {
                    return {
                        accordianTitle: e?.contact_name,
                        ...e
                    }
                })

                setScreenData({
                    ...screenData,
                    myTeam: {
                        ...screenData?.myTeam,
                        accordianContent: constructedData,
                        count: res?.data?.count
                    },
                    myDepartment: {
                        ...screenData?.myDepartment,
                        accordianContent: data?.department ? [
                            {
                                accordianTitle: data?.department?.name,
                                ...data?.department
                            }
                        ] : [],
                        count: 1
                    },
                    myCompany: {
                        ...screenData?.myCompany,
                        accordianContent: companyConstructedData,
                        count: data?.company?.count
                    },
                    myOrg: {
                        ...screenData?.myOrg,
                        accordianContent: orgConstructedData,
                        count: data?.organization?.count
                    }
                })
            })
            .catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    }

    // const getMyCompanyAndOrg = (type = "Company") => {
    //     const payload = {
    //         contact_id: myOrgData?.profile?.contact_id,
    //         limit: 5,
    //         offset: 0,
    //         org_role_type: type,
    //     };
    //     NetworkCall(
    //         `${config.api_url}/my_organisation/my_all_management_roles`,
    //         NetWorkCallMethods.post,
    //         payload,
    //         null,
    //         true,
    //         false
    //     )
    //         .then((res) => {

    //             const constructedData = res?.data?.data?.map((e) => {
    //                 return {
    //                     accordianTitle: e?.contact_name,
    //                     ...e
    //                 }
    //             })
    //             if (type === "Company") {
    //                 setScreenData({
    //                     ...screenData,
    //                     myCompany: {
    //                         ...screenData?.myCompany,
    //                         accordianContent: constructedData,
    //                         count: res?.data?.count
    //                     }
    //                 })
    //             } else {
    //                 setScreenData({
    //                     ...screenData,
    //                     myOrg: {
    //                         ...screenData?.myOrg,
    //                         accordianContent: constructedData,
    //                         count: res?.data?.count
    //                     }
    //                 })
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             alert.setSnack({
    //                 ...alert,
    //                 open: true,
    //                 severity: AlertProps.severity.error,
    //                 msg: t("Something Went Wrong"),
    //             });
    //         });
    // }

    const handleViewData = (data, type) => {
        setMyOrgData({
            ...myOrgData,
            viewCardBool: true,
            selectedViewData: data,
            viewReporter: type === "reporting" ? true : false
        })
    }

    const handleViewClose = () => {
        setMyOrgData({
            ...myOrgData,
            viewCardBool: false,
            selectedViewData: "",
            viewReporter: false
        })
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" style={{ padding: "0px" }}>
                <TitleBar text={t("My Org Tree")} goBack={goBack} arrow={true} />
                <Grid container style={{ backgroundColor: "#F6F6F6", justifyContent: "center" }}>
                    <Grid className={classes.boxSection}>
                        <Box className={classes.avatarImgBox} onClick={() => handleViewData(myOrgData?.profile)}>
                            <Avatar className={classes.avatarImg} src={myOrgData?.profile?.image_url} variant="circular" />
                        </Box>
                        <Typography className={classes.name}>{myOrgData?.profile?.first_name}</Typography>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} className={classes.subtitles}>
                            {myOrgData?.profile?.job_title && <Typography className={classes.title}>{myOrgData?.profile?.job_title}</Typography>}
                            {myOrgData?.profile?.department?.name && <Typography className={classes.department}>{myOrgData?.profile?.department?.name}</Typography>}
                        </Stack>
                        {/* <Typography className={classes.reportingname}>{t("Reporting to")} : {myOrgData?.profile?.reporting_manager?.first_name}</Typography> */}
                        <Box className={classes.reportingBox}>
                            <Divider fullWidth orientation="vertical" flexItem style={{ borderColor: dividerColor["Reporting"] }} className={classes.dividerSide} />
                            {/* <Box className={classes.managerCardBox}>
                                <ManagerCard
                                    title={myOrgData?.profile?.reporting_manager?.first_name}
                                    subTitle={myOrgData?.profile?.reporting_manager?.job_title}
                                    avatar={myOrgData?.profile?.image_url}
                                    t={t}
                                    open={managerCard}
                                    onClick={() => handleViewData(myOrgData?.profile)}
                                    onClose={() => setManagerCard(false)}
                                    dialogData={myOrgData?.profile}
                                />
                            </Box> */}

                            <SimpleAccordion
                                headText={"My Manager"}
                                defaultExpanded={true}
                                dividerColor={dividerColor["Reporting"]}
                                content={
                                    <>
                                        <ManagerCard
                                            title={myOrgData?.profile?.reporting_manager?.first_name}
                                            subTitle={myOrgData?.profile?.reporting_manager?.job_title}
                                            avatar={myOrgData?.profile?.reporting_manager?.image_url}
                                            t={t}
                                            open={managerCard}
                                            onClick={() => handleViewData(myOrgData?.profile, 'reporting')}
                                            onClose={() => setManagerCard(false)}
                                            dialogData={myOrgData?.profile}
                                        />
                                    </>

                                }

                            />
                        </Box>
                        <Box className={classes.accordianBox} marginTop={"24px"}>
                            {Object.values(screenData)?.map((item, index) => {
                                return (
                                    <>
                                        <Box key={index} className={classes.accordianBottom}>
                                            <Divider fullWidth orientation="vertical" flexItem style={{ borderColor: dividerColor[item?.key] }} className={classes.dividerSide} />
                                            <SimpleAccordion
                                                headText={item?.accordianTitle}
                                                defaultExpanded={index === 0 ? true : false}
                                                dividerColor={dividerColor}
                                                count={item?.count ?? "0"}
                                                content={
                                                    <>
                                                        <Grid container spacing={1.3}>
                                                            {
                                                                item?.accordianContent?.length > 0 ?
                                                                    <>
                                                                        {
                                                                            item?.accordianContent?.map((content, index) => {
                                                                                return (
                                                                                    <Grid item xs={12}>
                                                                                        <Box>
                                                                                            {components(item, content, index)}
                                                                                        </Box>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                    :
                                                                    <Box className={classes.noDataBox}>
                                                                        <Typography className={classes.noDataText}>{t("No Data Found")}</Typography>
                                                                    </Box>
                                                            }
                                                        </Grid>
                                                        {item?.accordianContent?.length > 1 ?
                                                            <Typography className={classes.viewAll}
                                                                onClick={() => item?.key === "My Company" ? viewRedirect("myCompany", myOrgData?.profile) :
                                                                    item?.key === "My Organization" ? viewRedirect("myOrganization", myOrgData?.profile) : viewRedirect("myTeam")}>
                                                                {t(viewTexts[item?.key])}
                                                            </Typography> : ""}
                                                    </>

                                                }

                                            />
                                        </Box>
                                    </>
                                )

                            })}
                        </Box>
                    </Grid>


                </Grid>

                <DialogDrawer
                    height={"370px"}
                    maxWidth={"sm"}
                    open={myOrgData?.viewCardBool}
                    onClose={() => handleViewClose()}
                    header={t("View profile")}
                    component={
                        <ViewCard
                            viewData={{
                                avatar: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.image_url : myOrgData?.selectedViewData?.reporting_manager?.image_url,
                                name: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.first_name : myOrgData?.selectedViewData?.reporting_manager?.first_name,
                                jobTitle: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.job_title : myOrgData?.selectedViewData?.reporting_manager?.job_title,
                                department: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.department?.name : myOrgData?.selectedViewData?.reporting_manager?.first_name,
                                reportingTo: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.reporting_manager?.reporting_to ?? "-" : myOrgData?.selectedViewData?.reporting_manager?.first_name ?? "-",
                                email: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.reporting_manager?.email_id : myOrgData?.selectedViewData?.email_id,
                                phone: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.phone : myOrgData?.selectedViewData?.phone,
                                mobile_no_country_code: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.mobile_no_country_code : myOrgData?.selectedViewData?.mobile_no_country_code,
                                mobile_no: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.mobile_no : myOrgData?.selectedViewData?.mobile_no
                            }}
                            t={t}
                        />
                    }
                />
            </Container>
        </div>
    );
};
export default withNamespaces("myOrganizations")(MyOrganizations);
