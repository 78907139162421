import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ListingTypeCard } from "./components/listingTypeCard"
import { useHistory } from "react-router-dom";
import { withBottombar } from "../../HOCs";
import { Box } from "@mui/material";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Requset = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // Top Tabs Funnctions


  const typeListing = [
    {
      id: 1,
      title: "Gate Passes",
      image: "/images/visitorIMG.svg",
      bgColor: "#FFF8E7"
    },
    {
      id: 2,
      title: "Parking Slot",
      image: "/images/parkingIMG.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 3,
      title: "Delivery Collection",
      image: "/images/delivery_collection_icon.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 4,
      title: "Shift In & Out",
      image: "/images/shift_in_out_icon.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 5,
      title: "Visitor Entry Request",
      image: "/images/visitor_entry_request_icon.svg",
      bgColor: "#F1F6FF"
    }
  ]

  const onClickCard=(type)=>{
    console.log(type,"kldkld")
    history.push(`${Routes.entries}/?type=${type?.id}`)
  }

  return (
    <div className={classes.root}>

      <Box p={2}>
      {
        typeListing?.map((val) => {
          return <ListingTypeCard details={val} onClick={onClickCard}/>
        })
      }
      </Box>


    </div>
  );
};

export default withBottombar(Requset, { note: false, hideTopNavIcons: true,bgColor:"#F6F6F6" });