import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import CheckInOutTimerIcon from './utils/checkInOutTimerIcon'
import CIOLocationIcon from './utils/cioLocation'
import CIOFuncLocationIcon from './utils/cioFuncLocation'
import { TitleBar } from '../../components'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles'
import { Bold, Regular, SemiBold, remCalc } from '../../utils'
import CIOActiveIcon from './utils/cioActiveIcon'
import { useLocation } from 'react-router-dom'
import { getCurrentLocationDetails, getHourmin } from './utils/checkInOut'
import { format } from 'date-fns'
import { withNamespaces } from 'react-i18next'

const CheckInOutDetailsStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.main,
        padding: "0px",
        overflow: "hidden",
        height: "calc(100vh - 0px)"
    },
    box: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "8px",
        padding: "16px",
        background: "#fff",
        height: "calc(100vh - 85px)",
        overflow: "auto"
    },
    time: {
        fontSize: remCalc(16),
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    popupCheckinText: {
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        fontFamily: Regular
    },
    locationAddress: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    },
    functionPrimaryText: {
        color: theme.typography.color.primary,
        fontSize: remCalc(12),
        fontFamily: SemiBold,
    },
    functionSecondaryText: {
        color: theme.typography.color.secondary,
        fontSize: remCalc(12),
        fontFamily: SemiBold,
    },
    functionalLocation: {
        background: theme.palette.background.secondary,
        borderRadius: 8,
        padding: 8
    },
    timeBox: {
        background: theme.palette.warning.light,
        color: theme.palette.warning.main,
        borderRadius: 8,
        padding: "6px",
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        marginTop: "8px"
    },
    detailTitle: {
        fontSize: remCalc(12),
        textTransform: "uppercase",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
    }
}))

const CheckInOutDetails = ({
    t = () => false
}) => {
    const classes = CheckInOutDetailsStyle()
    const history = useHistory()
    const state = useLocation()?.state

    const [detailData, setDetailData] = React.useState({
        checkin_add: "",
        checkout_add: ""
    })

    React.useEffect(() => {
        async function fetchData() {
            const checkinAdderss = await getCurrentLocationDetails(state?.in_lat, state?.in_long)
            const checkoutAdderss = await getCurrentLocationDetails(state?.out_lat, state?.out_long)
            setDetailData({
                ...detailData,
                checkin_add: checkinAdderss,
                checkout_add: checkoutAdderss
            })
        }
        fetchData();
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Container maxWidth="sm" sx={{ padding: "0px" }}>
                <div className={classes.root}>
                    <TitleBar text={"Detail"} goBack={() => history.goBack(-1)} />
                    <Box m={2} className={classes.box}>
                        <Stack alignItems={"center"} width={"100%"} justifyContent={"center"}>
                            <CheckInOutTimerIcon />
                            {state?.is_checked_out && <Typography className={classes.timeBox}>{`${getHourmin(`${state?.checkin_date} ${state?.checkin_time}`, `${state?.checkout_date} ${state?.checkout_time}`)}`}</Typography>}
                            <Stack rowGap={"6px"} mt={1}>
                                <Typography className={classes.time} style={{ textAlign: "center", width: "100%" }}>{`${format(new Date(`${state?.checkin_date} ${state?.checkin_time}`), "p")} ${state?.is_checked_out ? "-" : ""} ${state?.is_checked_out ? format(new Date(`${state?.checkout_date} ${state?.checkout_time}`), 'p') : ""}`}</Typography>
                                <Typography className={classes.popupCheckinText}>{`${t("Checking in")} - ${format(new Date(`${state?.checkin_date} ${state?.checkin_time}`), "dd MMM yy, cccc")}`}</Typography>
                            </Stack>
                        </Stack>

                        <Typography className={classes.detailTitle} mt={"32px"}>{t("Check In Details")}</Typography>

                        <Stack rowGap={2} mt={"12px"}>
                            <Stack columnGap={1} direction={"row"}>
                                <CIOActiveIcon />
                                <Stack rowGap={"4px"}>
                                    <Typography className={classes.locationAddress}>{format(new Date(`${state?.checkin_date} ${state?.checkin_time}`), "p")} {state?.in_timezone && `(${state?.in_timezone})`}</Typography>
                                    <Typography className={classes.popupCheckinText}>{t("Checking in time")}</Typography>
                                </Stack>
                            </Stack>
                            <Stack columnGap={1} direction={"row"}>
                                <CIOLocationIcon />
                                <Stack rowGap={"4px"}>
                                    <Typography className={classes.locationAddress}>{`${detailData?.checkin_add?.street ?? "-"}, ${detailData?.checkin_add?.address ?? "-"}`}</Typography>
                                    <Typography className={classes.popupCheckinText}>{t("Checking in location")}</Typography>
                                </Stack>
                            </Stack>
                            <Stack columnGap={1} direction={"row"} className={classes.functionalLocation} alignItems={"center"}>
                                <CIOFuncLocationIcon />
                                <Typography className={classes.functionPrimaryText}>{state?.in_distance ? (state?.in_distance).toFixed(1) : "-"} {t("Kms")} <span className={classes.functionSecondaryText}>{t("away from")}</span> {`"${state?.function_location?.function_location_name}"`}</Typography>
                            </Stack>
                        </Stack>

                        {state?.is_checked_out && <Box m={"16px 0px"}>
                            <Divider />
                        </Box>}

                        {state?.is_checked_out && <Typography className={classes.detailTitle}>{t("Check Out Details")}</Typography>}

                        {
                            state?.is_checked_out &&
                            <Stack rowGap={2} mt={"12px"}>
                                <Stack columnGap={1} direction={"row"}>
                                    <CIOActiveIcon />
                                    <Stack rowGap={"4px"}>
                                        <Typography className={classes.locationAddress}>{format(new Date(`${state?.checkout_date} ${state?.checkout_time}`), "p")} {state?.out_timezone && `(${state?.out_timezone})`}</Typography>
                                        <Typography className={classes.popupCheckinText}>{t("Checking out time")}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack columnGap={1} direction={"row"}>
                                    <CIOLocationIcon />
                                    <Stack rowGap={"4px"}>
                                        <Typography className={classes.locationAddress}>{`${detailData?.checkout_add?.street ?? "-"}, ${detailData?.checkout_add?.address ?? "-"}`}</Typography>
                                        <Typography className={classes.popupCheckinText}>{t("Checking out location")}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack columnGap={1} direction={"row"} className={classes.functionalLocation} alignItems={"center"}>
                                    <CIOFuncLocationIcon />
                                    <Typography className={classes.functionPrimaryText}>{state?.out_distance ? (state?.out_distance).toFixed(1) : "-"} {t("Kms")} <span className={classes.functionSecondaryText}>{t("away from")}</span> {`"${state?.function_location?.function_location_name}"`}</Typography>
                                </Stack>
                            </Stack>
                        }
                    </Box>
                </div>
            </Container>
        </>
    )
}

export default withNamespaces("dashboard")(CheckInOutDetails)