import {
  Badge,
  Box,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LoaderSimmer } from "../../components";
import Filter from "../../components/filter";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, Bold, enumSelect, enumTypes, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import InfiniteScroll from "react-infinite-scroll-component";
import { EntriesCard } from "./components/entriesCard";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  loader: {
    textAlign: "center",
    marginTop: "50%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const Delivery = (props) => {
  const { t = () => false } = props;

  const classes = useStyles();
  const size = useWindowDimensions();
  const { state } = useLocation();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [offset, setOffset] = React.useState(0);

  const [list, setList] = React.useState([]);
  const [property, setProperty] = React.useState();
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [filter, setFilter] = React.useState(false);
  const [unitList, setUnitList] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(true);
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
  const [enumValue, setEnumValue] = React.useState({
    type: [],
    status: [],
    objectKey: {}

  })

  const getListFromdashboard = (offset = 0, filter = true) => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/security-dashboard/${state?.type}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setList(response?.data?.data);
        } else {
          setList(list?.concat(response?.data?.data))
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const getPassList = (val, requestType, status, offset = 0, filter = true, dateFilterr = false) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: requestType,
      status: status,
      entries: false,
      property_id: dateFilterr ? undefined : val,
      unit_id: dateFilterr ? val : undefined,
      offset: offset,
      limit: 10,
      request_from_start_date:
        dateFilter?.startDate
        ?? undefined,
      request_from_end_date:
        dateFilter?.endDate
        ?? undefined,
    };
    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setFilter(false)
        if (filter) {
          setList(response?.data?.data);
        } else {
          setList(list?.concat(response?.data?.data))
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const payload = { roleID: roleId, limit: null };

    NetworkCall(
      `${config?.api_url}/queries/access_control/property_list`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      getEnum(response?.data?.data?.access_control?.map((val) => val?.property?.value))
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };

  //filter json

  const filterData = [
    {
      id: "1",
      title: t("Status"),
      key: "status",
      showSearch: true,
      filterType: "CHECKBOX",
      values: enumValue?.status ?? []
    },
    {
      id: "3",
      title: t("Unit"),
      key: "unit",
      filterType: "CHECKBOX",
      values: unitList,
    },
    // {
    //   id: "4",
    //   title: t("Date"),
    //   key: "date",
    //   filterType: "new_date",
    //   values: [],
    // },
  ];
  const filterUnits = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const payload = { roleID: roleId, limit: null };

    NetworkCall(
      `${config?.api_url}/queries/access_control/property_list`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      setUnitList(response?.data?.data?.access_control[0]?.property?.units);
      let data = response?.data?.data?.access_control[0]?.property?.units?.map((e)=>e?.value)
      if (state?.filterData && Object.keys(state?.filterData)?.length > 0) {
        getPassList(
          data,
          ["DCR"],
          state?.filterData?.status,
          0,
          true,
          true
        );
      }
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };
  //apply filter
  const applyFilter = (data) => {
    if (data?.unit.length > 0) {
      setSelectedFilter(data);
      getPassList(
        data?.unit,
        ["DCR"],
        data.status.length > 0
          ? data.status
          : [
            "Collected",
            "Yet To Receive",
            "Received At Gate"
          ],
        0,
        true,
        true
      );
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please Select Unit"),
      });
    }
  };

  const getEnum = async (data) => {
    const result = await enumSelect([enumTypes?.delivery_status_type, enumTypes?.security_request_type])
    let requestSourceMap = result?.security_request_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let requestSourceMapStatus = result?.delivery_status_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus)?.map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    setEnumValue({ ...enumValue, status: result?.delivery_status_type?.filter(_ => !["Cancelled"].includes(_?.value)), type: requestType, objectKey: requestTypeStatus })
    if (data?.length > 0 && !state?.filterData) {
      setProperty(data)
      getPassList(
        data,
        ["DCR"],
        [
          "Collected",
          "Yet To Receive",
          "Received At Gate"
        ]
      );
    }
  }
  const fetchMoreData = () => {
    setOffset(offset + 10);

    if(state?.filterData && Object.keys(state?.filterData)?.length > 0){
      getPassList(
        unitList?.map((e)=>e?.value),
        ["DCR"],
        selectedFilter?.status,
        offset + 10,
        false
      )
    } else if (state?.type === "deliveryRequest") {
      getListFromdashboard(
        offset + 10,
        false
      );
    } else {
      getPassList(
        property,
        ["DCR"],
        [
          "Collected",
          "Yet To Receive",
          "Received At Gate"
        ],
        offset + 10,
        false
      );
    }
  };
  React.useEffect(() => {
    if (clientId) {
      // getEnum()
      if(state?.filterData && Object.keys(state?.filterData)?.length > 0){
        filterUnits()
        setSelectedFilter({
          ...selectedFilter,
          status: state?.filterData?.status
        })
      }
      if (state?.type === "deliveryRequest") {
        getListFromdashboard();
      } else {
        getPropertyId();
      }
      if(!state?.filterData){
      filterUnits();
    }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <LoaderSimmer count={10} />

      ) : (
        <div className={classes.root}>
          <Box display="flex" alignItems="center" p={1}>
            <Box flexGrow={1}>
              {/* <TextField
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Search Delivery"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              height: "30px",
            }}
            fullWidth
            className={classes.search}
          /> */}
              <Typography className={classes.title}>
                {t("Delivery")} ({list?.length})
              </Typography>
            </Box>
            <Box marginLeft="10px">
              <IconButton
                className={classes.filter}
                onClick={() => setFilter(true)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <div
            style={{
              // height: size.height - 210,
              // overflow: "scroll",
              marginRight: "-4px",
              padding: "16px"
            }}
          >
            <InfiniteScroll
              dataLength={list?.length}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 110}
            >
              {list?.map((val) => {
                return <Box marginBottom={"10px"}>
                  {/* <DeliveryCard data={val} status={enumValue?.objectKey} /> */}
                  <EntriesCard
                    id={val?.id}
                    imgUrl={generateImageUrl(val?.vehicle_image, img_size.small_square)}
                    title={val?.description}
                    count={val?.gate ?? val?.collected_access_gate}
                    propertyName={val?.property_name}
                    unitNo={val?.unit?.[0]?.unit_no ?? val?.unit_no}
                    startDateString={val?.delivery_collected_date ? `${t("Collected Date")} -` : `${t("Request On")} -`}
                    startDate={val?.delivery_collected_date ?? val?.request_on}
                    type="pass"
                    status={val?.delivery_status}
                    selectedTab="delivery"
                  />
                </Box>
              })}
            </InfiniteScroll>
          </div>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={filter}
              onClose={() => {
                setFilter(false)
                setdateFilter({
                  startDate: "",
                  endDate: ""

                })
              }}
            >
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  status: selectedFilter.status ?? enumValue?.status?.map(i => i.label),
                  unit: selectedFilter.unit ?? unitList?.map(i => i.value),
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                onApply={applyFilter}
                onClose={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                updateState={updateState}
                data={dateFilter}
              />
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer open={filter} onClose={() => {
              setFilter(false)
              setdateFilter({
                startDate: "",
                endDate: ""

              })
            }}>
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  status: selectedFilter.status ?? enumValue?.status?.map(i => i.label),
                  unit: selectedFilter.unit ?? unitList?.map(i => i.value),
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                onApply={applyFilter}
                onClose={() => {
                  setFilter(false)
                  setdateFilter({
                    startDate: "",
                    endDate: ""

                  })
                }}
                updateState={updateState}
                data={dateFilter}
              />
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default withNamespaces("gatePass")(Delivery);

