import {
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TitleBar } from "../";
import DateTime from "../../components/date";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { CheckboxesGroup } from "./checkBoxFilter";
import { withNamespaces } from "react-i18next";
import { Bold, SemiBold } from "../../utils";
import { DatePickerNew } from "../datepickernew";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  active: {
    fontSize: "0.75rem",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
    display: "inline",
    fontFamily: Bold,
  },
  filterdata: {
    fontSize: "1rem",
    fontFamily:SemiBold
  },
  roots: {
    [theme.breakpoints.up("sm")]: {
      height: (props) => props.height - 220,
    },

    [theme.breakpoints.down("sm")]: {
      height: (props) => props.height - 154,
    },

    overflow: "scroll",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
    marginTop: "13px"
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
    fontFamily: Bold,
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
    fontFamily: Bold,
  },
}));

const filterTypes = {
  CHECKBOX: "CHECKBOX",
  DATE: "DATE",
};

const Filter = ({
  filterData = [],
  onGoBack = () => false,
  selectedList = {},
  filters = {},
  onApply = () => false,
  updateState = () => false,
  filterStartDate = {},
  onClose = () => false,
  allFilter = false,
  setUnitsfil = () => false,
  unitsfil = [],
  request = "",
  defaultData = {},
  data = {},
  setSelectedFilter = () => false,
  t
}) => {
  const size = useWindowDimensions();
  const classes = useStyles(size);

  const [state, setState] = React.useState({ selectedList, filters });

  const onListSelected = (event, filter) => {
    setState({ ...state, selectedList: filter });
  };

  const onApplyBtnClicked = () => {
    onApply(state.filters);
  };

  const onFilterChange = (key, values, type) => {
    let filters = state.filters;
    if (type === filterTypes.CHECKBOX) {
      if (values.isChecked) {
        filters[key].push(values.value);
      } else {
        filters[key].splice(filters[key].indexOf(values.value), 1);
        if (key === "agreement") {
          state.filters.units = [];
        }
      }
    }
    setState({ ...state, filters });
  };
  const onFilterAll = (e, array) => {
    var keys = array[0]?.key;
    let clearall = (state.filters[keys] = []);
    setState({ ...state, clearall });
    let filters = state.filters;

    if (e.target.checked) {
      array.forEach((item) => {
        filters[item.key].push(item.value);
      });
    } else {
      array.forEach((item) => {
        filters[item.key].splice(filters[item.key].indexOf(item.value), 1);
      });
    }

    setState({ ...state, filters });
  };

  const resetFilter = () => {
    if (filterData.length) {
      let filters = state.filters;
      filterData.forEach((filter) => {
        if (!filters?.[filter.key]?.length) {
          filters[filter.key] = [];
        }
      });
      setState({ ...state, filters });
    }
  };

  React.useEffect(() => {
    resetFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Grid container sx={{ padding: "0px" }}>
        {/* Header */}
        <Grid item xs={12}>
          <TitleBar text={t("Filter By")} goBack={onGoBack} />
        </Grid>

        {/* Body */}
        <Grid item xs={12} container className={classes.roots}>
          <Grid
            item
            xs={6}
            sx={{
              borderRight: "1px solid #c1c1c1",
            }}
          >
            <List component="nav">
              {filterData.map((filter, key) => {
                return (
                  <ListItemButton
                    key={key}
                    selected={state.selectedList.key === filter.key}
                    onClick={(event) => onListSelected(event, filter)}
                  >
                    <ListItemText  className={classes.filterdata} >
                      <Typography className={classes.filterdata} >
                      {filter.title}&nbsp;
                      <span className={classes.active}>
                        {data?.startDate &&
                          data?.endDate &&
                          filter.key === "date" ? (
                          <>1</>
                        ) : (
                          <>{state.filters?.[filter.key]?.length ?? 0}</>
                        )}
                      </span>
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          </Grid>

          {state.selectedList.key && (
            <Grid item xs={6} sx={{ padding: "12px" }}>
              <>
                {state.selectedList.filterType === filterTypes.CHECKBOX && (
                  <>
                    <CheckboxesGroup
                      selectedValues={state.filters[state.selectedList.key]}
                      onSelect={(values) =>
                        onFilterChange(
                          state.selectedList.key,
                          values,
                          filterTypes.CHECKBOX
                        )
                      }
                      onChangeAll={onFilterAll}
                      filters={state.selectedList.values}
                      state={state}
                    />
                  </>
                )}
              </>

              {state.selectedList.filterType === filterTypes.DATE && (
                <div style={{ padding: "12px" }}>
                  <DateTime
                    time={true}
                    title={t("Start Date")}
                    value={data.startDate ?? null}
                    onChange={(val) => updateState("startDate", val)}
                  />
                  <Box height="20px" />
                  <DateTime
                    title={t("End Date")}
                    time={true}
                    value={data.endDate ?? null}
                    onChange={(val) => updateState("endDate", val)}
                  />
                </div>
              )}
              {state.selectedList.filterType === "new_date" && (
                <div style={{ padding: "12px" }}>
                  <DatePickerNew
                    time={true}
                    label="Start Date"
                    value={data?.startDate ?? null}
                    onChange={(val) =>
                      updateState("startDate", val)
                    }
                    placeholder="Start Date"
                  />
                  <Box height="20px" />
                  <DatePickerNew
                    label="End Date"
                    time={true}
                    value={data?.endDate}
                    onChange={(val) =>
                      updateState("endDate", val)
                    }
                    placeholder="End Date"
                  />
                </div>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.btncontainer} spacing={1}>
          <Grid item xs={6} paddingTop={0}>
            <Button
              variant="contain"
              fullWidth={true}
              className={classes.cancelbtn}
              onClick={onClose}
            >
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6} paddingTop={0}>
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.applybtn}
              onClick={(e) => {
                e.persist();
                onApplyBtnClicked();
              }}
            >
              {t("apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default withNamespaces("gatePass")(Filter)