import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderSimmer } from "../../components";
import ServiceCard from "../../components/serviceCard"
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, Bold, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },

  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "89px",
      right: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "84px",
      right: "10px",
    },
  },
}));

const SwiftInOutListVendor = (props) => {
  const { t = () => false } = props
  const classes = useStyles();
  const { state } = useLocation();
  const size = useWindowDimensions();
  const history = useHistory();
  const [list, getList] = React.useState([]);
  const search = useLocation().search;
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const Title = new URLSearchParams(search).get("title");
  const alert = React.useContext(AlertContext);
  const [load, setLoad] = React.useState(true);
  const [property, setProperty] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
console.log("state from ===>", state)

  const getListFromdashboard = (offset = 0, filter = true) => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/security-dashboard/${state?.type}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let dataList = response?.data?.data?.map((val) => {
          return {
            id: val?.id,
            title: val?.vendorname ?? "-",
            check_out_time: val?.checkedouttime,
            subtitle1: val?.personname ?? "-",
            subtitle2: val?.referenceid ?? "-",
            img: "/images/domestic.svg",
          };
        });
        if (filter) {

          getList(dataList);
        } else {
          getList(list?.concat(dataList))
        }
        setLoad(false)

      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };
  const getPassList = (val, offset = 0, filter = true) => {
    const payload = {
      visitorType: Title === "Service" ? ["SP"] : ["VE"],
      property_id: val,
      offset: offset,
      limit: 10,
    };

    NetworkCall(
      `${config?.api_url}/queries/swift_in_out_log/list`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      let dataList = response?.data?.data?.swift_in_out_log?.map((val) => {
        return {
          id: val?.id,
          title: val?.vendor_master?.name ?? "-",
          check_out_time: val?.check_out_time,
          subtitle1: val?.person_name ?? "-",
          subtitle2: val?.reference_id ?? "-",
          img: "/images/domestic.svg",
        };
      });
      if (filter) {

        getList(dataList);
      } else {
        getList(list?.concat(dataList))
      }
      setLoad(false)
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    })
  };
  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;

    const payload = { roleID: roleId };

    NetworkCall(
      `${config?.api_url}/queries/access_control/property_list`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      getPassList(
        response?.data?.data?.access_control?.map((val) => val?.property?.value)
      );
      setProperty(response?.data?.data?.access_control?.map((val) => val?.property?.value))
    }).catch((err) => {
      console.log(err);
      alert.setSnack({
        ...alert, open: true, msg: t("Something Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };
  const fetchMoreData = () => {
    setOffset(offset + 10);
    if(state?.filterData && Object.keys(state?.filterData)?.length > 0){
      getPassList(
        property,
        offset + 10,
        false
      );
    }else if (state?.type) {
      getListFromdashboard(
        offset + 10,
        false
      );
    } else {
      getPassList(
        property,

        offset + 10,
        false
      );
    }
  };
  React.useEffect(() => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "",
    // });
    if(state?.filterData && Object.keys(state?.filterData)?.length > 0){
      getPropertyId();
    } else if (state?.type) {
      getListFromdashboard();
    } else {
      getPropertyId();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <TitleBar
        text="Vendor Entry"
        // color="#5078E1"
        goBack={() => history.push(Routes.dashboard)}
      /> */}
      {load ? (
        <LoaderSimmer count={10} />
      ) : (
        <div className={classes.root}>
          <Box display="flex" alignItems="center" p={1} position="sticky">
            <Box flexGrow={1}>
              <Typography className={classes.title}>
                {t("Vendor")} ({list?.length})
              </Typography>
            </Box>
          </Box>
          <div
            style={{
              // height: size.height - 120,
              // overflow: "auto",
              marginRight: "-6px",
            }}
          >
            <InfiniteScroll
              dataLength={list?.length}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 120}
            >
              {list?.length > 0 &&
                list?.map((val) => <ServiceCard data={val} Title={Title} />)}

              <Fab
                color="primary"
                className={classes.fab}
                onClick={() => history.push(`${Routes.create}${"?create="}${"Vendor"}`)}
              >
                <AddIcon />
              </Fab>
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
};
export default withNamespaces("swiftInOutDetails")(SwiftInOutListVendor)