import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProfileSimmaer } from "../../components/simmer/profileSimmer";
import { config } from "../../config";
import { AlertContext, AuthContext, FirebaseContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Bold, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods, Regular, RetainLocalStorage, SemiBold } from "../../utils";
import { DialogDrawer } from "../../components/dialogDrawer";
import { LanguageList } from "./components/languageList";
import { TranslateIcon } from "./components/TranslateIcon";
import { TermsCondition } from "./assets/condition";
import { TermOfService } from "./assets/service";
import { PrivacyPolicy } from "./assets/privacy";
import { CookiePolicy } from "./assets/cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme.palette.dashboard.primary,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  // screen: {
  //   borderRadius: "16px",
  //   border: "1px solid #F5F7FA",
  //   background: theme.palette.background.tertiary,
  // },
  topNavBarStyle: {
    // padding: "0px 16px 0px 16px",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  profileCardStyle: {
    padding: "24px 16px 16px 16px",
    backgroundImage: `url(${"/images/Illustration.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    background: theme.palette.background.tertiary1,
  },
  detailsCardStyle: {
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
  bottomNavigationBarStyle: {
    borderRadius: "20px 20px 0px 0px",
    position: "fixed",
    width: "-webkit-fill-available",
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  drawerHeaderTextStyle1: {
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontFamily: Regular,
    color: "#FFF",
  },
  drawerButtonStyle1: {
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: "white",
    fontSize: "0.875rem",
    fontFamily: Regular,
    color: theme.palette.primary.main,
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
}));



const ProfileView = (props) => {
  const { t = () => false, i18n } = props;
  const auth = React.useContext(AuthContext)
  const language = localStorage.getItem("i18nextLng")
  const classes = useStyles();
  const history = useHistory();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const firebaseContext = React.useContext(FirebaseContext);
  const [data, setDate] = React.useState({
    user: {},
    role: {}
  })
  const [openDrawer, setopenDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);
  const [languageModal, setLanguageModal] = React.useState({
    open: false,
    language: null,
  });

  const LanguageOptions = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "ar", label: "Arabic" },
    { value: "fr", label: "French" },]
  const [selectedLanguage, setSelectedLanguage] = React.useState(LanguageOptions?.[0])
  const [openLanguage, setOpenLanguage] = React.useState(false)

  const company = JSON.parse(localStorage.getItem("accessType"));

  const [is_terms_policies_open, set_is_terms_policies_open] = React.useState(false);
  const [aboutCompany, setAboutCompany] = React.useState(null)
  const [terms_policies_viewer_state, set_terms_policies_viewer_state] = React.useState({
    title: "",
    content: "",
  });
  const [is_terms_policies_viewer_open, set_is_terms_policies_viewer_open] = React.useState(false);

  const openLink = (url) => {
    window.open(url, "_blank", "noreferrer")
  };
  const Logout = () => {
    setopenDrawer(true);
  };
  const handleAboutUs = (type) => {
    if (type === "company") {
      window.open(company?.[0]?.company_website ? `${'http://' + company?.[0]?.company_website}` : "https://dnetsoft.com/", "_blank");
    }
    if (type === "aboutapp") {
      window.open(aboutCompany?.about_us_url, "_blank");

    }
    if (type === "privacy") {
      window.open("https://propgoto.com/privacy.html", "_blank");

    }
    if (type === "terms") {
      window.open("https://propgoto.com/termsOfService.html", "_blank");

    }
  };


  const getTermsPolicies = () => {
    NetworkCall(
      `${config.api_url}/client/get_app_configuration`,
      NetWorkCallMethods.get,
      data, null, true, false
    ).then((response) => {
      let _ = response?.data?.data;
      setAboutCompany({
        title: _?.company_name ? `${t("About")} (${_?.company_name})` : `${t("About")}`,
        website: _?.company_website ? `http://${_?.company_website}` : null,
        about_us_url: _?.about_us_url ? _?.about_us_url : null,
        ..._,
        sub_menus: [
          {
            icon: (<TermsCondition />),
            title: t("terms & conditions"),
            sub_title: t("Propgoto"),
            content: _?.terms_conditions,
            type: "terms_condition"
          },
          {
            icon: (<TermOfService />),
            title: t("term of service"),
            sub_title: "Property Automate",
            content: _?.term_of_service,
            type: "term_of_service"
          },
          {
            icon: (<PrivacyPolicy />),
            title: t("privacy policy"),
            sub_title: "Property Automate",
            content: _?.privacy_policy,
            type: "privacy_policy"
          },
          {
            icon: (<CookiePolicy />),
            title: t("cookie policy"),
            sub_title: "Property Automate",
            content: _?.cookie_policy,
            type: "cookie_policy"
          },
        ]
      })
    })
      .catch((error) => {
        alert.setSnack({
          ...alert, open: true, msg: t("SomethingWentwrong"),
          severity: AlertProps.severity.error,
        });
      });
  };
  React.useEffect(() => {
    getTermsPolicies()
    if (authToken !== null && authToken !== "") {

      NetworkCall(
        `${config.api_url}/user-info`,
        NetWorkCallMethods.get,
        null, null, true,
        false).then((response) => {
          setDate({
            user: response?.data?.data?.user_profiles?.[0],
            role: response?.data?.data?.role?.[0]
          })
          setLoading(false);
        }).catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong."),
          });
        });
    } else {
      RetainLocalStorage();
      history.push(Routes.login);
    }
    let getselectedlag = i18n.language;
    setLanguageModal({
      ...languageModal,
      language:
        LanguageOptions.filter((val) => val.value === getselectedlag)?.[0] ??
        LanguageOptions[0],
    });
    // eslint-disable-next-line
  }, []);

  const updateLanguage = (data) => {
    setSelectedLanguage(data)
    saveLanguage(data)
  }

  const openLanguageSwitcher = () => {
    let getselectedlag = props?.i18n.language
    setSelectedLanguage(LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    setOpenLanguage(true)
  }

  const saveLanguage = (language) => {
    props?.i18n.changeLanguage(language?.value);
    setOpenLanguage(false)
    auth.setAuth({ ...auth })
  };
  // logout
  const getLogoutdeviceToken = () => {
    const params = {
      token: localStorage.getItem(LocalStorageKeys?.deviceToken),
      app_build: config.app_x_build,
    };
    NetworkCall(
      `${config.api_url}/logout`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        RetainLocalStorage()
        history.push(Routes.login);
      })
      .catch((error) => {
        RetainLocalStorage();
        history.push(Routes.login);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong."),
        });
      });
  };
  const handleTermsPolicies = () => {
    set_is_terms_policies_open(!is_terms_policies_open);
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      {loading ? (
        <ProfileSimmaer />
      ) : (
        <>
          <Grid
            className={classes.screenScrollEffect}
          // style={{ height: size?.height - (64 + 62), width: "inherit", marginTop: "2px" }}
          >
            <Grid className={classes.screen} item xs={12}>
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                className={classes.profileCardStyle}
              >
                <Grid>
                  <Avatar
                    variant="circular"
                    style={{
                      height: "128px",
                      width: "128px",
                      border: "2px solid #5078E1",
                    }}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "100%",
                        width: "100%",
                        border: "2px solid #ffffff",
                      }}
                    >
                      <img
                        src={

                          generateImageUrl(data?.user?.image_url, img_size.small_square) ?? "/images/profile_image.png"
                        }
                        alt="profile_image"
                        style={{
                          height: "128px",
                          width: "128px",
                        }}
                      />
                    </Avatar>
                  </Avatar>
                  <Button
                    sx={{ marginRight: language === "ar" && "45px" }}
                    variant="contained"
                    style={{
                      padding: "4px 7px 4px 7px",
                      marginTop: "-30px",
                      minWidth: "35px",
                      height: "24px",
                      marginLeft: "45px",
                      backgroundColor: "#5078E1",
                      border: "2px solid #F5F7FA",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#FFFFFF",
                        fontFamily: Bold
                      }}
                    >
                      <div onClick={() => history.push(Routes.profileEdit)}>
                        {t("edit")}
                      </div>
                    </Typography>
                  </Button>
                </Grid>
                <Box width="26px" />
                <Grid>
                  <Typography
                    style={{
                      fontSize: "0.9375rem",
                      fontFamily: Bold,
                      color: "#091B29"
                    }}
                    noWrap
                  >
                    {data?.user?.first_name ?? ""}&nbsp;{data?.user?.last_name ?? ""}
                  </Typography>
                  <Box height={"8px"} />
                  <Grid container direction={"row"}>
                    <Box
                      style={{
                        padding: "4px 8px 4px 8px",
                        border: "1px solid #27AFCF",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "0.75rem",
                          fontFamily: Bold,
                          color: "#27AFCF",
                        }}
                      >
                        {t("Security")}
                      </Typography>
                    </Box>
                    <Box width="8px" />
                    {
                      data?.role?.client_role_no &&
                      <Box
                        style={{
                          padding: "4px 8px 4px 8px",
                          border: "1px solid #6A68CC",
                          borderRadius: "4px",
                        }}
                      >


                        <Typography
                          style={{
                            fontSize: "0.75rem",
                            fontFamily: Bold,
                            color: "#6A68CC",
                          }}
                        >
                          {data?.role?.client_role_no ?? ""}
                        </Typography>


                      </Box>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ padding: "16px" }}>
                <Grid
                  container
                  direction={"row"}
                >
                  <Grid
                    style={{}}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_39.svg"})`,
                        height: "112px",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#46B9DA",
                        }}
                      >
                        <img src="/images/icons8-phone.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      <Typography
                        style={{
                          fontSize: "0.875rem",
                          color: "#4E5A6B",
                          fontFamily: Bold,
                          direction: "ltr",
                          textAlign: "-webkit-match-parent"
                        }}
                        noWrap
                      >
                        {data?.user?.mobile_no_country_code ?? ""}&nbsp;
                        {data?.user?.mobile_no ?? ""}
                      </Typography>
                      <Box height={"6px"} />
                      <Typography
                        style={{
                          fontSize: "0.875rem",
                          color: "#4E5A6B",
                          fontFamily: Bold,
                        }}
                        noWrap
                      >
                        {data?.user?.alternative_mobile_no_country_code ?? ""}&nbsp;
                        {data?.user?.alternative_mobile_no ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ paddingInlineStart: "16px" }}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_37.svg"})`,
                        // height: "-webkit-fill-available",
                        height: "112px",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#6A68CC",
                        }}
                      >
                        <img src="/images/icons8-mail.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      <Typography
                        style={{
                          fontSize: "0.875rem",
                          color: "#4E5A6B",
                          fontFamily: Bold,
                        }}
                        noWrap
                      >
                        {data?.user?.email_id}
                      </Typography>
                      <Box height={"6px"} />
                      <Typography
                        style={{
                          fontSize: "0.875rem",
                          color: "#4E5A6B",
                          fontFamily: Bold,
                        }}
                        noWrap
                      >
                        {data?.user?.alternative_email_id ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box height={"16px"} />
                <Grid container direction={"column"}>
                  <Grid
                    container
                    display="inline"
                    direction={"column"}
                    className={classes.detailsCardStyle}
                    style={{
                      backgroundImage: `url(${"/images/Mask_Group_38.svg"})`,
                    }}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "28px",
                        width: "28px",
                        backgroundColor: "#FF4B4B",
                      }}
                    >
                      <img src="/images/icons8-map-marker.svg" alt="" />
                    </Avatar>
                    <Box height="12px" />
                    <Typography
                      style={{
                        fontSize: "0.875rem",
                        color: "#4E5A6B",
                        fontFamily: Bold,
                        wordWrap: "break-word",
                      }}
                    >
                      {data?.user?.contact?.street_1 ? `${data?.user?.contact?.street_1}, ` : ""}{data?.user?.contact?.street_2 ? `${data?.user?.contact?.street_2}, ` : ""}
                      {data?.user?.contact?.district ? `${data?.user?.contact?.district}, ` : ""}
                      {data?.user?.contact?.state ? `${data?.user?.contact?.state}, ` : ""}
                      {data?.user?.contact?.country ? `${data?.user?.contact?.country}, ` : ""}
                      {data?.user?.contact?.zipcode ? `- ${data?.user?.contact?.zipcode}` : ""}


                    </Typography>
                    <Box height={"12px"} />
                  </Grid>
                </Grid>


                <Box height={"16px"} />

                {/* Defualt Language chaange */}
                <Grid
                  onClick={openLanguageSwitcher}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    {/* <img src="/images/signout.svg" alt="signout" /> */}
                    <TranslateIcon />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "0.875rem",
                        color: "#091B29",
                        fontFamily: Bold,
                        wordWrap: "break-word",
                      }}
                    >
                      {t("defualt_language")} - ({" "}
                      {selectedLanguage?.label} )
                    </Typography>
                  </Grid>
                  {language === "ar" ?
                    <ChevronLeftIcon /> :
                    <ChevronRight />
                  }
                </Grid>


                <Box height={"16px"} />

                <Grid
                  onClick={() => !!aboutCompany?.website ? openLink(aboutCompany?.website) : false} container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/aboutCompanyName.svg" alt="aboutCompanyName" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "0.875rem",
                        color: "#091B29",
                        fontFamily: Bold,
                        wordWrap: "break-word",
                      }}
                    >
                      {aboutCompany?.title}
                    </Typography>
                  </Grid>
                  {language === "ar" ?
                    <ChevronLeftIcon /> :
                    <ChevronRight />
                  }
                </Grid>
                <Box height={"16px"} />

                <Grid
                  onClick={() => handleAboutUs("aboutapp")}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/aboutApp.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "0.875rem",
                        color: "#091B29",
                        fontFamily: Bold,
                        wordWrap: "break-word",
                      }}
                    >
                      {t("About App")}
                    </Typography>
                  </Grid>
                  {language === "ar" ?
                    <ChevronLeftIcon /> :
                    <ChevronRight />
                  }
                </Grid>

                <Box height={"16px"} />

                <Grid
                  onClick={() => handleAboutUs("privacy")}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/privacyPolicy.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "0.875rem",
                        color: "#091B29",
                        fontFamily: Bold,
                        wordWrap: "break-word",
                      }}
                    >
                      {t("Privacy Policy")}
                    </Typography>
                  </Grid>
                  {language === "ar" ?
                    <ChevronLeftIcon /> :
                    <ChevronRight />
                  }
                </Grid>
                <Box height={"16px"} />

                <Grid
                  onClick={handleTermsPolicies} container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/termsIcon.svg" alt="" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "0.875rem",
                        color: "#091B29",
                        fontFamily: Bold,
                        wordWrap: "break-word",
                      }}
                    >
                      {t("Terms of Service")}
                    </Typography>
                  </Grid>
                  {language === "ar" ?
                    <ChevronLeftIcon /> :
                    <ChevronRight />
                  }
                </Grid>


                <Box height={"16px"} />
                <Grid
                  onClick={Logout}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "0.875rem",
                        color: "#091B29",
                        fontFamily: Bold,
                        wordWrap: "break-word",
                      }}
                    >
                      {t("log_out")}
                    </Typography>
                  </Grid>
                  {language === "ar" ?
                    <ChevronLeftIcon /> :
                    <ChevronRight />
                  }
                </Grid>

                <Box height={"16px"} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "0.75rem",
                    color: "#091B29",
                  }}
                >
                  {"V" + localStorage.getItem(LocalStorageKeys.version)}
                </Typography>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={openDrawer}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.dialogStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("confirmation")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      {t("are_you")}
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          {t("cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            getLogoutdeviceToken();
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          {t("log_out")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={openDrawer}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("confirmation")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      {t("are_you")}
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          {t("cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            RetainLocalStorage();
                            history.push(Routes.login);
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          {t("log_out")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>


            {/* Modal Language chaange */}

            <DialogDrawer
              height={"400px"}
              open={openLanguage}
              header={t("Choose Language")}
              padding={"0px 16px 16px 16px"}
              component={
                <LanguageList
                  languageOptions={LanguageOptions}
                  selectedLanguage={selectedLanguage}
                  updateLanguage={updateLanguage}
                />
              }
              onClose={() => setOpenLanguage(false)} />
          </Grid>
        </>
      )}

      <DialogDrawer
        height={"400px"}
        open={is_terms_policies_open}
        onClose={() => set_is_terms_policies_open(false)}
        header={t("terms & policies")}
        padding={"16px"}
        header_text_transform={"capitalize"}
        component={
          <Stack direction={"column"} spacing={"12px"}>
            {aboutCompany?.sub_menus?.map((key) => {
              return (
                key?.content &&
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #E4E8EE",
                    borderRadius: "8px",
                    padding: "16px",
                  }}
                  onClick={() => {
                    set_terms_policies_viewer_state({
                      title: key?.title,
                      content: key?.content,
                    });
                    set_is_terms_policies_viewer_open(true);
                    set_is_terms_policies_open(false);
                  }}>
                  <Stack spacing={"12px"} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    {key?.icon}
                    <Stack direction={"column"} spacing={"5px"}>
                      <Typography
                        sx={{
                          fontFamily: Bold,
                          fontSize: "0.875rem",
                          color: "#091B29",
                          textTransform: "capitalize",
                        }}>
                        {key?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: SemiBold,
                          fontSize: "0.75rem",
                          color: "#4E5A6B",
                          textTransform: "capitalize",
                        }}>
                        {key?.sub_title}
                      </Typography>
                    </Stack>
                  </Stack>
                  <ChevronRight htmlColor="#98A0AC" />
                </Stack>
              )
            })}
          </Stack>
        } />


      <DialogDrawer
        height={"100%"}
        open={is_terms_policies_viewer_open}
        onClose={() => {
          set_is_terms_policies_open(true);
          set_is_terms_policies_viewer_open(false);
        }}
        header={t(terms_policies_viewer_state?.title)}
        padding={"16px"}
        header_text_transform={"capitalize"}
        maxWidth={"sm"}
        component={
          <div dangerouslySetInnerHTML={{ __html: terms_policies_viewer_state?.content }} />
        } />
    </Container>
  );
};
export default withNamespaces("profile")(ProfileView);