import { Box, Button, Container, Grid, Stack, Divider } from "@mui/material";
import { LoadingSection, SelectBox, TextBox, TitleBar } from "../../components"
import { useStyles } from "./styles"
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import React from "react";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { constructFillParam, escortState } from "./utils";
import { ProfileUpload } from "../../components/profileUpload";
import { VechileType } from "./component";
import { loadOptionsApis } from "../../utils/loadOptions";
import { FourWheel } from "../../assets/fourWheel"

const CreateEscort = ({ t = () => false }) => {

    const searchURL = useLocation().search;
    const history = useHistory();
    const passId = new URLSearchParams(searchURL).get("passId");
    const alert = React.useContext(AlertContext);
    const [state, setState] = React.useState({ ...escortState });
    const [disabled, setDisabled] = React.useState(false);
    const size = useWindowDimensions();
    const profile_id = localStorage.getItem("user_id");

    const classes = useStyles()

    const getDetails = () => {
        const payload = {
            id: passId
        }
        NetworkCall(
            `${config.api_url}/walkInout/details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                if(res?.data?.id){
                    const fill_param=constructFillParam(res?.data)
                    setState({
                        ...fill_param,
                        loading: false,
                    })
                }else{
                    history.push(Routes?.entries)
                }
               
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        if(passId){
        getDetails()
        }else{
            history.push(Routes?.entries)
        }
        // eslint-disable-next-line
    }, [])

    const goBack = () => {
        history.push(`${Routes.walkInDetails}?passId=${passId}`)
    }

    const updateState = (key, value) => {
        let error = state.error;
        error[key] = "";
        setState({ ...state, [key]: value, error });
    };

    const isFormValid = () => {

        let isValid = true;
        let error = state.error;

        if (!state?.resource) {
            isValid = false;
            error.resource = t("Security Guard is Required")
        }
        if (state?.person_name?.length === 0) {
            isValid = false;
            error.person_name = t("Person to Meet is Required")
        }
        if (state?.id_proof?.length === 0) {
            isValid = false;
            error.id_proof = t("ID Proof is Required")
        }
        if (state?.id_number?.length === 0) {
            isValid = false;
            error.id_number = t("ID Number is Required")
        }
        // if (state?.vehicle_type && state?.vehicle_no?.length === 0) {
        //     isValid = false;
        //     error.vehicle_no = t("Vehicle Number is Required")
        // }
        setState({ ...state, error });

        return isValid;
    };

    const onSubmit = () => {
        if (isFormValid()) {
            setDisabled(true)
            const payload = {
                is_swift_in: true,
                is_walk_in: state?.is_update?false: true,
                swift_in_param: {
                    id: passId,
                    status: "Approved",
                    updated_by: profile_id,
                    updated_at: new Date(),
                    identification_proof: state?.id_proof?.value,
                    identification_no: state?.id_number,
                    security_resource_id: state?.resource?.value,
                    vehicle_type: state?.vehicle_type,
                    vehicle: state?.vehicle_no,
                    person_to_meet: state?.person_name,
                    escort_person_image: state?.person_image?.src,
                    vehicle_image: state?.vehicle_image?.src,
                },
                walk_in_param:state?.is_update ?undefined: {
                    status: "Approved",
                    request_id: passId,
                    created_by: profile_id,
                    agreement_unit_user:state?.agreement_unit_user,
                    security_guard_id: profile_id,
                    message: "Approved By Escort"
                }
            }
            NetworkCall(
                `${config.api_url}/walkInout/upsert_walk_in_log`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    setDisabled(false)
                    goBack()
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: t(state?.is_update?"Escort details updated":"Escort details successfully added"),
                      });
        
                })
                .catch((err) => {
                    setDisabled(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Could not Approved Escort"),
                      });
                    console.log(err);
                });
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill All Mandatory Fields"),
              });

        }

    }


    return (
        <Container maxWidth="sm" style={{ padding: "0px" }}>
            <Box>

                <TitleBar goBack={goBack} text={t("Escort")} />
                {
                    state?.loading ?
                        <LoadingSection message={t("...loading")} top="30vh" />
                        :

                        <Box>
                            <Box p={2} className={classes.detailRoot} style={{ height: size?.height - 140 }}>
                                <Stack justifyContent="space-around" direction="row" divider={<Divider orientation="vertical" flexItem />} mb="12px">
                                    <ProfileUpload
                                        logo_title={t("Person Image")}
                                        handleChange={(url) => updateState("person_image", url)}
                                        data={state?.person_image}
                                    />
                                    <ProfileUpload
                                        logo_title={t("Vehicle Image")}
                                        handleChange={(url) => updateState("vehicle_image", url)}
                                        data={state?.vehicle_image}
                                        icon={<FourWheel />}
                                    />

                                </Stack>
                                <SelectBox
                                    label={t("Security guard")}
                                    placeholder={t("Select security guard")}
                                    isPaginate={true}
                                    onChange={(val) => updateState("resource", val)}
                                    value={state?.resource}
                                    isRequired
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "walkInout/escort_resource",
                                            { "search": search, pass_id: passId },
                                            search,
                                            array,
                                            handleLoading,
                                            "data"
                                        )
                                    }
                                    isError={state?.error?.resource?.length > 0}
                                    errorMessage={state?.error?.resource}
                                />
                                <Box height="12px" />
                                <TextBox
                                    color
                                    label={t("Person to Meet")}
                                    placeholder={t("Enter Person to Meet")}
                                    onChange={(e) => {if(e.target.value?.length<30){updateState("person_name", e.target.value)}}}
                                    value={state?.person_name}
                                    isRequired
                                    isError={state?.error?.person_name?.length > 0}
                                    errorMessage={state?.error?.person_name}
                                    fontSize="0.75rem !important"
                                />
                                <Box height="12px" />
                                <SelectBox
                                    label={t("ID Proof")}
                                    placeholder={t("Select ID Proof")}
                                    isPaginate={true}
                                    onChange={(val) => updateState("id_proof", val)}
                                    value={state?.id_proof}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "queries/identification_master",
                                            { "search": search },
                                            search,
                                            array,
                                            handleLoading,
                                            "data"
                                        )
                                    }
                                    isRequired
                                    isError={state?.error?.id_proof?.length > 0}
                                    errorMessage={state?.error?.id_proof}
                                />
                                <Box height="12px" />
                                <TextBox
                                    color
                                    label={t("ID Number")}
                                    onChange={(e) => updateState("id_number", e.target.value)}
                                    value={state?.id_number}
                                    placeholder={t("Enter ID Number")}
                                    isRequired
                                    isError={state?.error?.id_number?.length > 0}
                                    errorMessage={state?.error?.id_number}
                                />
                                <VechileType
                                    onChange={(val) => updateState("vehicle_type", val)}
                                    value={state?.vehicle_type}
                                    t={t} />
                                <TextBox
                                    color
                                    label={t("Vehicle Number")}
                                    placeholder={t("Enter Vehicle Number")}
                                    onChange={(e) => updateState("vehicle_no", e.target.value)}
                                    value={state?.vehicle_no}
                                    
                                />
                            </Box>
                            <Grid container p={1} className={classes.btnRoot}>
                                <Grid item xs={12} p={1}>
                                    <Button
                                        disabled={disabled}
                                        onClick={onSubmit}
                                        className={classes.revokeBtn} fullWidth variant="contained">
                                        {t(state?.is_update? "Update":"Create")}
                                    </Button>
                                </Grid>

                            </Grid>
                        </Box>
                }
            </Box>
        </Container>
    )
}
export default withNamespaces("walkInRequest")(CreateEscort)