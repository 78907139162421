import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Container, Dialog, Divider, Drawer, FormControl, FormControlLabel, Grid, Hidden, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import jwt_decode from "jwt-decode";
import React from 'react';
import { withNamespaces } from "react-i18next";
import { QrReader } from "react-qr-reader";
import { useHistory, useLocation } from 'react-router-dom';
import { TextBox, TitleBar } from '../../components';
import { config } from '../../config';
import { AlertContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { Routes } from "../../router/routes";
import { AlertProps, Bold, ExtraBold, LocalStorageKeys, NetWorkCallMethods, SemiBold, remCalc } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";







const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.main,
    },
    text: {
        color: theme.typography.color.primary,
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "6px",
        fontFamily: SemiBold
    },
    bottomBarTitle: {
        fontSize: "1.125rem",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "8px",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    iconButtonStyle: {
        height: "44px",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        color: "#5078E1",
        background: "#5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "0px 2px 6px #00000021",
    },
    saveNUpdateButtonTextStyle: {
        fontSize: "0.875rem",
        color: "#FFFFFF",
        fontFamily: Bold
    },
    saveNUpdateButtonContainerStyle: {
        padding: "16px",
        background: "white",
        border: "1px solid #E4E8EE",
        boxShadow: "0px -7px 20px #0717410D",
        // position: "fixed",
        width: "-webkit-fill-available",
    },
    profilePicturePadding: {
        justifyContent: "center",
        padding: "24px 16px 24px 16px",
    },
    qrcode: {
        border: "10px solid #5078E1",
        borderRadius: "8px",
        "& section > div": {
            border: "none !important",
            boxShadow: "none !important",

        }
    },
    grid: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: "16px"
        },
    },
    scanQRText: {
        fontSize: remCalc(14),
        fontFamily: SemiBold
    },
    enterManualQRBtn: {
        fontSize: remCalc(14),
        fontFamily: SemiBold
    },
    gateLabel: {
        "& .MuiTypography-root": {
            fontSize: remCalc(14),
            fontFamily: Bold
        }
    }
}))
const GetPassVerification = props => {
    const { t = () => false } = props
    const history = useHistory();
    const classes = useStyles();
    const size = useWindowDimensions();
    const [gateOpen, setGateOpen] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const [open, setOpen] = React.useState(false)
    const [gateNo, setGateNo] = React.useState([])
    const [selected, setSelected] = React.useState({})
    const [qrCode, setQrCode] = React.useState("")
    const searchURL = useLocation().search;
    const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
    const Domestic = new URLSearchParams(searchURL).get("title");

    const handleError = (err) => {
        console.error(err);
    };
    const RequestApi = (val) => {
        const data = {
            tenantId: `${config.tenantid}`,
            qr_no: val
        };
        NetworkCall(
            `${config.api_url}/security/request`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response.status === 200) {
                    history.push(`${Routes.gateEntry}${"?qrcode="}${val?.text ?? val}${"&gate_id="}${selected?.id}`)
                }

            })
            .catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Invalid QR code !"),
                });

            });
    }
    const DomesticHelper = (val) => {
        const data = {
            qrCode: val,
            clientId: clientId


        }
        NetworkCall(
            `${config.api_url}/domestic_helper/details`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.data?.type && response?.data?.data?.type === "barred") {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: response?.data?.data?.message,
                    });
                    history.push(`${Routes.getPassVerification}${"?title="}${"Domestic"}`)
                } else {
                    history.push(`${Routes.gateEntry}${"?qrcode="}${val?.text ?? val}${"&domestic="}${"Domestic"}${"&property_id="}${selected?.property?.id}${"&gate_id="}${selected?.id}`)
                }

            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Invalid QR code !"),
                });

            });
    }
    //qr api
    const QrCodeApi = (val) => {

        if (val) {
            if (Domestic === "Domestic") {
                DomesticHelper(val?.text ?? val)
            }
            else {
                RequestApi(val?.text ?? val)
            }
        }
    }

    React.useEffect(() => {
        let authToken = localStorage.getItem(LocalStorageKeys.authToken);
        let decode = jwt_decode(authToken);
        let security_id = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))

        const payload = {
            user_id: decode.id,
            role_id: security_id?.[0]?.id
        };

        NetworkCall(
              `${config?.api_url}/queries/user_profile/getPassVerification`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            let main = response?.data?.data;

            let user_profiles = main?.user_profiles;
            let access_gates_master = main?.access_gates_master;

            localStorage.setItem("user_profile_id", user_profiles?.[0]?.id);

            let selectedId = access_gates_master?.filter((val) => val?.user_profile_id === user_profiles?.[0]?.id)?.[0];

            setSelected(selectedId ? selectedId : access_gates_master?.[0])

            setGateNo(access_gates_master)
        }).catch((err) => {
            console.log(err);
            alert.setSnack({
                ...alert, open: true, msg: t("Something Went Wrong"),
                severity: AlertProps.severity.error,
            });
        })
        // eslint-disable-next-line
    }, []);
    return <div >
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
            <TitleBar
                text={Domestic === "Domestic" ? t("Domestic_Pass_Verification") : t("Gate_Pass_Verification")}
                // color="#5078E1"
                goBack={() => history.push(Routes.dashboard)}
            />
            <div className={classes.root}>
                <Grid Container
                    style={{ height: size?.height - (107), width: "inherit", overflow: "auto" }}
                >
                    <br />
                    <center>
                        <span className={classes.text}>
                            &nbsp;{selected?.name} &nbsp;
                            <IconButton
                                size="small"
                                style={{
                                    color: "black",
                                }}
                                onClick={() => setGateOpen(true)}
                            >
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        </span>
                    </center>
                    <br />
                    <center>
                        <Grid item xs={12} style={{ overflow: "auto" }}>
                            <QrReader
                                delay={500}
                                onError={handleError}
                                onResult={QrCodeApi}
                                style={{ width: "320px" }}
                                className={classes.qrcode}
                                constraints={{ facingMode: "environment" }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.scanQRText}>{t("Scan_QR_Code")}</Grid>
                    </center>
                </Grid>
                <Grid container className={classes.grid}>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant='text'
                            onClick={() => setOpen(true)}
                            className={classes.enterManualQRBtn}
                        >
                            {t("Enter_QR_Number_Manually")}
                        </Button>
                        <br />
                    </Grid>
                </Grid>
            </div>
            {/* gate  hidden */}
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={gateOpen}
                    onClose={() => setGateOpen(false)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography
                                variant="subtitle2"
                                className={classes.bottomBarTitle}
                            >
                                {t("Select_Gate")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton size="small" onClick={() => setGateOpen(false)}>
                                <CloseIcon color="secondary" />
                            </IconButton>
                        </Box>
                    </Box>
                    <FormControl component="fieldset" >
                        <RadioGroup
                            aria-label="gender"
                            defaultValue="female"
                            name="radio-buttons-group"
                            style={{ padding: "8px" }}
                        >
                            {gateNo?.map((val) => {
                                return (
                                    <>
                                        <FormControlLabel
                                            value={val?.gate_no}
                                            control={
                                                <Radio
                                                    checked={
                                                        val?.id === selected?.id
                                                            ? true
                                                            : false
                                                    }


                                                />
                                            }
                                            label={val?.name}
                                            onChange={() => setSelected(val)}
                                            className={classes.gateLabel}
                                        />

                                        <Divider />
                                    </>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>

                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={gateOpen}
                    onClose={() => setGateOpen(false)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography
                                variant="subtitle2"
                                className={classes.bottomBarTitle}
                            >
                                {t("Select_Gate")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton size="small" onClick={() => setGateOpen(false)}>
                                <CloseIcon color="secondary" />
                            </IconButton>
                        </Box>
                    </Box>
                    <FormControl component="fieldset" >
                        <RadioGroup
                            aria-label="gender"
                            defaultValue="female"
                            name="radio-buttons-group"
                            style={{ padding: "8px" }}
                        >
                            {gateNo?.map((val) => {
                                return (
                                    <>
                                        <FormControlLabel
                                            value={val?.gate_no}
                                            control={
                                                <Radio
                                                    checked={
                                                        val?.id === selected?.id
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            }
                                            label={val?.name}
                                            onChange={() => setSelected(val)}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Drawer>
            </Hidden>
            {/* open drawer */}
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.bottomBarTitle}
                                    >
                                        {t("Manual_QR_Number")}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={() => setOpen(false)}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} p={2} margin={"6px"}>
                            <TextBox label={t("QR_Number")} value={qrCode} onChange={(e) => setQrCode(e.target.value)} />
                        </Grid>
                        <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.iconButtonStyle}
                                onClick={() => QrCodeApi(qrCode)}
                            >
                                <Typography className={classes.saveNUpdateButtonTextStyle}>
                                    {t("Verify")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.bottomBarTitle}
                                    >
                                        {t("Manual_QR_Number")}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={() => setOpen(false)}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} p={2} margin={"6px"}>
                            <TextBox label={t("QR_Number")} value={qrCode} onChange={(e) => setQrCode(e.target.value)} />
                        </Grid>
                        <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.iconButtonStyle}
                                onClick={() => QrCodeApi(qrCode)}
                            >
                                <Typography className={classes.saveNUpdateButtonTextStyle}>
                                    {t("Verify")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </Drawer>
            </Hidden>
        </Container>
    </div>
}
export default withNamespaces("gatePassVerification")(GetPassVerification);
