import { Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Card, TitleBar } from '../../components';
import DetailsCard from "../../components/detailsCard"
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, Bold, customEnumModifier, enumSelect, enumTypes, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    text: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.Tertiary,
        padding: "8px",
        marginTop: "4px"

    }
}))

const GateEntry = props => {
    const { t = () => false } = props
    const history = useHistory();
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const search = useLocation().search;
    const val = new URLSearchParams(search).get("qrcode");
    const Domestic = new URLSearchParams(search).get("domestic");
    const propertyId = new URLSearchParams(search).get("property_id");
    const gateId = new URLSearchParams(search).get("gate_id");
    const size = useWindowDimensions();
    const [loading, setLoading] = React.useState(true);
    const [gateData, setGateData] = React.useState([])
    const [proofData, setProofData] = React.useState([]);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState("");
    const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
    const [enumValue, setEnumValue] = React.useState({
        type: [],
        status: [],
        objectKey: {}

    })
    //openImageViewer
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const RequestApi = (val) => {
        const data = {
            tenantId: `${config.tenantid}`,
            qr_no: val
        };
        NetworkCall(
            `${config.api_url}/security/request`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    setGateData(response?.data?.data[0])
                }

            })
            .catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Invalid QR code !"),
                });
                history.push(Routes.getPassVerification)

            });
    }



    const DomesticHelper = (val) => {
        const data = {
            qrCode: val,
            clientId: clientId


        }
        NetworkCall(
            `${config.api_url}/domestic_helper/details`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.data?.type && response?.data?.data?.type === "barred") {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: response?.data?.data?.message,
                    });
                    history.push(`${Routes.getPassVerification}${"?title="}${"Domestic"}`)
                } else {
                    setGateData(response?.data?.data)
                    setLoading(false)
                }

            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Invalid QR code !"),
                });
                history.push(Routes.getPassVerification)

            });
    }



    // const DomesticHelpercheckout
    const domesticCheckout = (id) => {
        setLoading(false)
        DomesticHelper(val)
    }

    const getProofData = (WGR, VGR) => {
        if (gateData?.request_type === WGR || gateData?.request_type === VGR) {

            NetworkCall(
                `${config.api_url}/identification/identification`,
                NetWorkCallMethods.post,
                {},
                null,
                true,
                false
            )
                .then((response) => {

                    setProofData(response?.data?.data?.data);
                })
                .catch((error) => {
                    console.log(error);
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something Went Wrong"),
                    });
                });
        }
    };
    //enum
    const getEnum = async (data) => {

        const result = await enumSelect([enumTypes?.security_status_enum_type, enumTypes?.security_request_type])
        let requestSourceMap = result?.security_request_type?.flatMap((item, index) => {
            return {
                [`type${index + 1}`]: item?.value
            }
        })

        let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key])?.reduce((old, item) => (
            { ...old, ...item }
        ), {})
        let requestSourceMapStatus = result?.security_status_enum_type?.flatMap((item, index) => {
            return {
                [`type${index + 1}`]: item?.value
            }
        })

        let requestTypeStatus = Object.keys(requestSourceMapStatus)?.map(key => requestSourceMapStatus[key])?.reduce((old, item) => (
            { ...old, ...item }
        ), {})
        let options = {
            filterKey: "Received At Gate",
            replaceKey: "In Progress",
            actualKey: "In Progress",
            data: result?.security_status_enum_type
        }
        let status = customEnumModifier(options)
        setEnumValue({ ...enumValue, status: status, type: requestType, objectKey: requestTypeStatus })
        getProofData("WGR", "VGR")

    }
    React.useEffect(() => {
        getEnum()
        if (Domestic === "Domestic") {
            DomesticHelper(val)
        }
        else if (val) {
            RequestApi(val)
        }
        // eslint-disable-next-line
    }, [])

    return <div >
        <>
            {loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    {Domestic === "Domestic" ?
                        <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
                            <TitleBar
                                text={t("Gate Entry")}
                                // color="#5078E1"
                                goBack={() => history.goBack()}
                            />
                            <Grid
                                container
                                className={classes.screenScrollEffect}
                                style={{ height: size?.height - (64), width: "inherit", overflow: "auto" }}
                            >
                                <Grid item xs={12} sx={{ padding: "12px 12px 12px 16px" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Card t={t} data={gateData} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.text}>

                                            </Typography>
                                        </Grid>
                                        {Domestic === "Domestic" &&
                                            <Grid item xs={12}>
                                                <DetailsCard
                                                    data={gateData} Reload={() => DomesticHelper(val)} gateId={gateId} propertyId={propertyId}
                                                    statusKey={enumValue?.type}
                                                    domesticCheckout={domesticCheckout} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Container>
                        :

                        <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
                            {gateData?.request_type === "WGR" &&
                                <TitleBar
                                    text={t("View Worker Gate Pass")}
                                    // color="#5078E1"
                                    goBack={() => history.goBack()}
                                />
                            }
                            {gateData?.request_type === "VGR" &&
                                <TitleBar
                                    text={t("View Visitor Gate Pass")}
                                    // color="#5078E1"
                                    goBack={() => history.goBack()}
                                />
                            }
                            {gateData?.request_type === "PSR" &&
                                <TitleBar
                                    text={t("Parking Slot")}
                                    // color="#5078E1"
                                    goBack={() => history.goBack()}
                                />
                            }
                            <Grid
                                container
                                className={classes.screenScrollEffect}
                                style={{ height: size?.height - (64), overflow: "auto" }}
                            >
                                <Grid item xs={12} sx={{ padding: "12px" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Card t={t} data={gateData} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.text}>
                                                {gateData?.request_type === "WGR" && gateData?.visitors.length > 0 && t("WORKER DETAILS")}
                                                {gateData?.request_type === "VGR" && gateData?.visitors.length > 0 && t("VISITOR DETAILS")}
                                                {gateData?.request_type === "PSR" && t("VEHICLE DETAILS")}
                                            </Typography>
                                        </Grid>
                                        {gateData?.visitors &&
                                            <Grid item xs={12}>
                                                {gateData?.visitors?.map((item) =>
                                                    <>
                                                        <DetailsCard

                                                            data={item}
                                                            Reload={() => RequestApi(val)}
                                                            ReloadEnum={() => getProofData("WGR", "VGR")}
                                                            type={gateData?.request_type}
                                                            proofData={proofData}
                                                            closeImageViewer={closeImageViewer}
                                                            openImageViewer={openImageViewer}
                                                            isViewerOpen={isViewerOpen}
                                                            currentImage={currentImage}
                                                            statusKey={enumValue?.type}
                                                            parentDataId={gateData?.id}
                                                            gateId={gateId}
                                                            parentData={gateData}
                                                            setIsViewerOpen={setIsViewerOpen}
                                                        />
                                                        <br />
                                                    </>
                                                )}
                                            </Grid>
                                        }
                                        {gateData?.vechicle_type &&
                                            <Grid item xs={12}>
                                                <DetailsCard

                                                    parentData={gateData}
                                                    data={gateData} Reload={() => RequestApi(val)}
                                                    statusKey={enumValue?.type}
                                                    type={gateData?.request_type}
                                                    gateId={gateId}
                                                    ReloadEnum={() => getProofData("WGR", "VGR")}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Container>}
                </>

            )
            }
        </>
    </div>
}
export default withNamespaces("gateEntry")(GateEntry)