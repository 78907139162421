import { Box, Stack, Typography, Button, Avatar } from "@mui/material";
import { useStyles } from "./styles"
import PurpleUnit from "../../../assets/purpleUnit";
import React from "react";
import { DialogDrawer } from "../../../components";
import TickImageComponent from "../../../assets/tickImage";
import { AlertContext } from "../../../contexts";
import { AlertProps } from "../../../utils";

export const UnitCard = ({ t = () => false, data = {}, onChooseUnit = () => false,value={},is_open=false }) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const alert = React.useContext(AlertContext);

    const openDrawer=()=>{
        if(is_open){
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.info,
                msg: t("Please Choose Gate"),
            });
        }else{
            setOpen(true)
        }
    }

    return (
        <Box className={classes.cardRoot}>
            <Stack direction="row" alignItems="center" padding="12px 0px 8px 0px">
                {
                    data?.logo ?
                        <Avatar
                            src={data?.logo}
                            style={{ borderRadius: "8px", height: "40px", width: "40px", objectFit: "cover" }}
                        /> :
                        <PurpleUnit
                            color="#98A0AC"
                            rectColor="#F2F4F7"
                        />
                }

                <Box sx={{ marginInlineStart: 4 }}>
                    <Typography className={classes.unitName}>{data?.name ?? "-"}</Typography>
                    <Typography className={classes.location}>{data?.tenant?.length > 0 ? `${data?.tenant?.[0]?.account_name} (${data?.tenant?.[0]?.account_id})` : "-"}</Typography>
                </Box>

            </Stack>

            {/* <Stack direction="row" sx={{ marginTop: "10px" }} justifyContent="space-between">
                <Typography className={classes.detTitle}>{t("Resident Name")}</Typography>
                <Typography className={classes.detDescription}>{data?.tenant ?? "-"}</Typography>
            </Stack>
            <Stack direction="row"
                sx={{
                    margin: "14px 0px",
                    padding: "14px 0px",
                    borderTop: "1px solid #E4E8EE",
                    borderBottom: "1px solid #E4E8EE"
                }}
                justifyContent="space-between">
                <Typography className={classes.detTitle}>{t("Owner Name")}</Typography>
                <Typography className={classes.detDescription}>{data?.owner ?? "-"}</Typography>
            </Stack>
            <Stack direction="row" sx={{ marginTop: "12px" }} justifyContent="space-between">
                <Typography className={classes.detTitle}>{t("Occupants")}</Typography>
                <Typography className={classes.detDescription}>{data?.occpants ?? "-"}</Typography>
            </Stack> */}

            <Button disabled={data?.tenant?.length === 0} onClick={openDrawer} variant="contained" fullWidth className={classes.unitCardBtn}>
                {t("Raise Visitor Entry Request")}
            </Button>

            <DialogDrawer
                open={open}
                onClose={() => setOpen(false)}
                header="Choose Tenant"
                component={
                    <Box style={{height:"300px",overflow:"scroll"}}>
                        {
                            data?.tenant?.map((val) => {
                                console.log(value , val?.id)
                                return (
                                    <Box display="flex" sx={{cursor:"pointer",borderBottom:"1px solid rgb(228, 232, 238)",paddingBottom:"12px"}} alignItems="center" onClick={()=>onChooseUnit(data,val)}>
                                        <Box>
                                            <Avatar sx={{width:"40px",height:"40px"}} src={val?.image_url?.length > 0 ? val?.image_url : null} />
                                        </Box>
                                        <Box flexGrow={1} marginInlineStart={"12px"}>
                                            <Typography className={classes.detTitle}>{val?.first_name}</Typography>
                                        </Box>
                                        <Box>
                                            {
                                            value?.user?.id === val?.id && <TickImageComponent/>
                                            }
                                        </Box>

                                    </Box>
                                )
                            })
                        }

                    </Box>
                }


            />

        </Box>
    )
}