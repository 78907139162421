import { Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Bold, SemiBold, generateImageUrl, img_size, remCalc } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "12px",
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: "90px",
    display: "flex",
    width: '100%',
    padding: "12px"
  },
  Cardcontent: {
    padding: "8px 8px",
  },
  title: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
  },
  count: {
    fontSize: remCalc(20),
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },

  image: {
    width: 46,
    height: 46,
    backgroundColor: (props) => props?.data?.color,
  },
  sideBorder: {
    backgroundColor: (props) => props?.data?.borderColor,
    margin: " 3px 0px",
    border: (props) => `3px solid ${props?.data?.borderColor}`,
    minHeight: "90px",
    width: "7px"
  }
}));

export const HomepageCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  // const language = localStorage.getItem("i18nextLng")

  return (

    <div
      className={classes.card}
      onClick={() => {
        history.push({
          pathname: props?.data?.link,
          search: props?.data?.type ? `?type=${props?.data?.type}` : "",
          state: {
            type: props?.data?.redirectLink,
            filterData: props?.data?.filterData ?? ""
          },
        });

        localStorage.setItem(
          "selected",
          props?.data?.redirect ? props?.data?.redirect : 0
        );
      }}
    >
      {/* <Grid container >
        <Grid item xs={1} sm={1}>
          <Box sx={{ borderRadius: language === "ar" ? "10px 0px 0px 10px" :  "0px 10px 10px 0px"}} className={classes.sideBorder} />
        </Grid>
        <Grid item xs={11} sm={11}>
          <Box display="flex" justifyContent={"space-between"} className={classes.Cardcontent}>
            <Typography variant="body1" className={classes.count}>
              {props?.data?.count}
            </Typography>
            <img src={generateImageUrl(props?.data?.image, img_size.small_square)} alt="" />
          </Box>
          <Grid item xs={12} padding={"0px 8px"}>
          {props?.entries ? (
            <>
              <Typography
                variant="subtitle2"
                className={classes.title}
                noWrap
              >
                {props?.data?.name}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title}
                noWrap
              >
                {props?.data?.name1}
              </Typography>
            </>
          ) : (
            <Typography variant="subtitle2" className={classes.title}>
              {props?.data?.name}
            </Typography>
          )}
        </Grid>
        </Grid>
    
      </Grid> */}

      <Stack sx={{ width: "100%" }} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography className={classes.count}>
            {props?.data?.count}
          </Typography>
          <img src={generateImageUrl(props?.data?.image, img_size.small_square)} alt="" />
        </Stack>
        {props?.entries ? (
          <>
            <Typography
              variant="subtitle2"
              className={classes.title}
              noWrap
            >
              {props?.data?.name}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title}
              noWrap
            >
              {props?.data?.name1}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle2" className={classes.title}>
            {props?.data?.name}
          </Typography>
        )}
      </Stack>

    </div>
  );
};
